import { useEffect, useState } from 'react'
import { RadioInput, RadioInputGroup } from '@instructure/ui'
import { useRadioGroup } from '../../../../mc-ui/utils/hooks/forms/useRadioGroupWithReactHookForm'
import { FormValues, ScaleProps } from '../types'
import { useShellContentConsumersResource } from '../hooks/queries'
import { useWatch } from 'react-hook-form'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'

const Scale = ({
  scales = [],
  defaultScaleId: userDefaultScaleId,
  disabled = false,
}: ScaleProps) => {
  const [defaultScaleId, setDefaultScaleId] = useState(userDefaultScaleId)
  const [defaultScaleChange, setDefaultScaleChange] = useState(false)
  const { data: districts } = useShellContentConsumersResource()
  const districtId = useWatch({ name: 'destinationDistrict.id' })

  const field = useRadioGroup<FormValues, 'scale.id'>({
    name: 'scale.id',
    defaultValue: defaultScaleId,
  })

  //On destinationDistrict.id value change, find the scale associated with that district and set as new default for the component
  useEffect(() => {
    const foundScale = districts?.find(
      (element) => element.id === districtId
    )?.scale
    const id = foundScale?.id ?? userDefaultScaleId
    setDefaultScaleId(id)
    setDefaultScaleChange(true)
  }, [districtId, districts, userDefaultScaleId])

  useEffect(() => {
    if (defaultScaleChange) {
      field.onChange(null, defaultScaleId)
      setDefaultScaleChange(false)
    }
  }, [defaultScaleId, defaultScaleChange, field])

  return (
    <RadioInputGroup disabled={disabled} description={'Scale'} {...field}>
      {scales.map((scale) => {
        return (
          <RadioInput
            key={scale.id}
            value={scale.id}
            label={`${scale.name}${
              scale.id === defaultScaleId ? ' (Default)' : ''
            }`}
            {...selectorAttrs(`add-assessment-modal-scale--${scale.id}`)}
          />
        )
      })}
    </RadioInputGroup>
  )
}
export { Scale }

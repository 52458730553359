import { useController, UseControllerProps, useWatch } from 'react-hook-form'
import {
  SimpleSelect,
  Text,
  View,
  IconInfoLine,
  Spinner,
} from '@instructure/ui'
import type { SimpleSelectProps } from '@instructure/ui-simple-select'
import { InfoPopover } from '../../../mc-ui/elements/InfoPopover'
import { SourceType } from '../../../mc-resources/materials'
import { PrivacyLevel } from '../../../mc-resources/internal/types'
import type { MaterialPrivacyConfig } from './types'

const ControlledSimpleSelect = ({
  name,
  rules,
  defaultValue,
  children,
  ...rest
}: UseControllerProps & SimpleSelectProps) => {
  const { field } = useController({ name, rules, defaultValue })

  return (
    <SimpleSelect
      {...field}
      {...rest}
      onChange={(e, { value }) => field.onChange(value)}
    >
      {children}
    </SimpleSelect>
  )
}

export const PrivacySelector = ({
  config,
  disabled = false,
  options = [],
  isFetchingOptions = false,
  defaultOption,
  ...rest
}: {
  config: MaterialPrivacyConfig
  disabled?: boolean
  options: Array<{ value: PrivacyLevel; label: string }>
  isFetchingOptions?: boolean
  defaultOption: PrivacyLevel
  'data-qa'?: string
  'data-impact-id'?: string
}) => {
  const sourceType = useWatch({ name: 'sourceType' })
  const assessmentType = useWatch({ name: 'assessmentType' })

  if (isFetchingOptions) {
    return (
      <View display="block" textAlign="center">
        <Spinner size="small" renderTitle="Loading privacy level selector" />
      </View>
    )
  }

  return (
    <ControlledSimpleSelect
      key={sourceType + assessmentType}
      disabled={disabled}
      name="privacyLevel"
      placeholder="Select Privacy"
      rules={{ required: true }}
      defaultValue={defaultOption}
      renderLabel={() => (
        <>
          <View margin="0 x-small 0 0">
            <Text>Privacy</Text>
          </View>

          {[SourceType.DOCUMENT, SourceType.RAW].includes(sourceType) && (
            <PrivacyPopover />
          )}
        </>
      )}
      data-qa={rest['data-qa']}
      data-impact-id={rest['data-impact-id']}
    >
      {options?.map(({ label, value }) => (
        <SimpleSelect.Option key={value} id={`privacy-${value}`} value={value}>
          {label}
        </SimpleSelect.Option>
      ))}
    </ControlledSimpleSelect>
  )
}

const PrivacyPopover = () => {
  return (
    <InfoPopover
      placement="top center"
      screenReaderLabel="Privacy info"
      renderTrigger={<IconInfoLine />}
    >
      <InfoPopover.Title>Privacy</InfoPopover.Title>
      <InfoPopover.Content>
        If you are uploading copyrighted work, please remember to mark it as
        private to your district, your school, or to you.
      </InfoPopover.Content>
    </InfoPopover>
  )
}

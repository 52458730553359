import { FilterOptionOption } from './types'

function defaultSerializeOptionForSearch<Option>(
  option: FilterOptionOption<Option>
) {
  return `${option.label}`
}

export type FilterConfig<Option> = {
  serializeOptionForSearch?: (option: FilterOptionOption<Option>) => string
  ignoreCase?: boolean
  trim?: boolean
}

function createFilter<Option>(config?: FilterConfig<Option>) {
  return (option: FilterOptionOption<Option>, inputValue: string) => {
    const {
      serializeOptionForSearch = defaultSerializeOptionForSearch,
      ignoreCase = true,
      trim = true,
    } = config || {}

    let serializedOption = trim
      ? serializeOptionForSearch(option).trim()
      : serializeOptionForSearch(option)

    let processedInputValue = trim ? inputValue.trim() : inputValue

    if (ignoreCase) {
      processedInputValue = processedInputValue.toLowerCase()
      serializedOption = serializedOption.toLowerCase()
    }

    return serializedOption.indexOf(processedInputValue) > -1
  }
}

export { createFilter, defaultSerializeOptionForSearch }

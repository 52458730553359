import { useController } from 'react-hook-form'
import { Text, TextInput } from '@instructure/ui'
import type { FormMessage } from '@instructure/ui-form-field'
import { useAddFormContext } from '../hooks/useAddForm'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'

export const Title = ({ disabled = false }) => {
  const { field } = useController({
    name: 'title',
    rules: { required: true, maxLength: 255 },
  })
  const { formState } = useAddFormContext()

  const type = formState.errors.title?.type ? 'error' : 'hint'
  const messages: FormMessage[] = [
    { type, text: (field.value?.length || 0) + '/255' },
  ]

  return (
    <TextInput
      disabled={!!disabled}
      renderLabel={
        <>
          Title <Text color="danger">*</Text>
        </>
      }
      value={field.value}
      onBlur={field.onBlur}
      onChange={(event) => field.onChange(event.target.value)}
      messages={messages}
      {...selectorAttrs('add-assessment-modal-title')}
    />
  )
}

export default function generateComponentTheme({colors, shadows, stacking, media, spacing}) {
    return {
      background: colors.backgroundLightest,
      color: colors.textDarkest,
      boxShadow: shadows.depth2,
      zIndex: stacking.topmost + 1,
      spacingSmall: 0,
      bottomSpacingMedium: 0,
      spacingLarge: spacing.small,
      ...media,
    }
}
import * as React from 'react'
import { Button, Flex, Text } from '@instructure/ui'
import { Heading } from '@instructure/ui-heading'
import { ErrorBoundary } from 'react-error-boundary'

import { SourceType } from '../../../../mc-resources/materials'
import { ConfigProps, FormValues } from '../types'
import { ConfigProvider } from '../contexts/ConfigContext'
import { FormProvider, useForm } from 'react-hook-form'
import { StrictMode } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AlertsProvider } from '../../../../mc-ui/context/AlertsContext'
import { Alerts } from '../../../../mc-ui/elements/Alerts'

type AppProviderProps = {
  children: React.ReactNode
} & ConfigProps

const ErrorFallback = () => {
  return (
    <Flex
      as="div"
      justifyItems={'center'}
      alignItems={'center'}
      direction={'column'}
      role="alert"
      height={'100vh'}
    >
      <Text color={'danger'}>
        <Heading>Oops, something went wrong :(</Heading>
      </Text>
      <Button margin={'large 0 0 0'} onClick={() => window.location.reload()}>
        Refresh
      </Button>
    </Flex>
  )
}

export const AppProvider = ({ children, ...props }: AppProviderProps) => {
  const defaultConfig = React.useMemo(() => {
    const defaultValues = {
      title: '',
      sourceType: SourceType.ITEM_BANK,
      assessmentType: props.distbenchmark ? 'benchmark' : 'formative',
      destinationDistrict: { id: null },
      materialCsv: null,
      // Don't add default privacyLevel value here, as it's being handled by PrivacySelector
    } as FormValues

    return defaultValues
  }, [props.distbenchmark])

  const methods = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: defaultConfig,
  })

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <FormProvider {...methods}>
          <AlertsProvider>
            <Alerts />
            <StrictMode>
              <ConfigProvider
                config={{ ...props, defaultValues: defaultConfig }}
              >
                {children}
              </ConfigProvider>
            </StrictMode>
          </AlertsProvider>
        </FormProvider>
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

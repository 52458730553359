import PropTypes from 'prop-types'

import { Text, View } from '@instructure/ui'

const InfoPopoverTitle = ({children}) => {
  return (
      <View as={"div"} padding={"0 large 0 0"} margin={"0 0 xx-small 0"}>
        <Text weight={"bold"}>{children}</Text>
      </View>
  )
}

InfoPopoverTitle.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node
}

export default InfoPopoverTitle
export {InfoPopoverTitle}
import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Transition, withStyle } from '@instructure/ui'

import generateStyle from './styles'
import generateComponentTheme from './theme'

//  @TODO: add clickaway if needed, add
//  @TODO: add autoHideDuration
//  @TODO: create SnackBarContent component if needed(with standard styling, message, closeButton and action)
/**
 * Snackbars provide brief messages about app processes. The component is also known as a toast.
 * check https://material.io/components/snackbars#usage for more info
 */
const SnackbarComponent = (props) => {
    const {
        children,
        transition = 'fade',
        open = true,
        onDismiss,
        position: { vertical, horizontal } = { vertical: 'bottom', horizontal: 'center' },
        styles,
        makeStyles
    } = props

    useEffect(() => {
      makeStyles()
      // disabled on initial eslint-plugin-react-hooks configuration
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vertical, horizontal])

    const onExitTransition = () => {
        if (onDismiss) {
            onDismiss()
        }
    }

    const renderSnackbar = () => {
        return <div css={styles.root}>{children}</div>
    }

    if (transition === 'none') {
        return open ? renderSnackbar() : null
    }

    return <Transition
        type={transition}
        in={open}
        unmountOnExit
        onExited={onExitTransition}
    >
        {renderSnackbar()}
    </Transition>
}

SnackbarComponent.propTypes = {
    /**
     * If `true`, `Snackbar` is open.
     */
    open: PropTypes.bool,
    /**
     * Content to render.
     */
    children: PropTypes.element,
    /**
     * Transition used to make the alert appear and disappear
     */
    transition: PropTypes.oneOf(['none', 'fade']),
    /**
     * The position of the `Snackbar`.
     * @default { vertical: 'bottom', horizontal: 'left' }
     */
    position: PropTypes.shape({
        horizontal: PropTypes.oneOf(['center', 'left', 'right']).isRequired,
        vertical: PropTypes.oneOf(['bottom', 'top']).isRequired,
    }),
    makeStyles: PropTypes.func,
    onDismiss: PropTypes.func,
    styles: PropTypes.object,
}

SnackbarComponent.defaultProps = {
    open: true,
    transition: 'fade',
    position: { vertical: 'bottom', horizontal: 'center' }
}

const Snackbar = withStyle(generateStyle, generateComponentTheme)(SnackbarComponent)

export { Snackbar, SnackbarComponent }
export default Snackbar
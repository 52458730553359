import { AbstractResource } from '../AbstractResource'
import { snakeCaseKeys } from 'mc-sdk/case-utils'

class MaterialReportsQueryResource extends AbstractResource {
  constructor(materialPath) {
    super()
    this._endpointUrl = materialPath
  }

  buildResourceUri = (params) => {
    const queryParams = snakeCaseKeys(params, true)

    const path = this._endpointUrl
    const queryStr = this._buildQueryString(queryParams)
    return `${path}${queryStr}`
  }
}

export { MaterialReportsQueryResource }

import { useCallback, useRef, useState } from 'react'
import type { AlertProps } from '@instructure/ui-alerts'

type Optional<Type> = {
  [Property in keyof Type]?: Type[Property]
}

export type Alert = {
  message: string | JSX.Element
} & Optional<
  Pick<
    AlertProps,
    | 'variant'
    | 'margin'
    | 'liveRegion'
    | 'liveRegionPoliteness'
    | 'isLiveRegionAtomic'
    | 'screenReaderOnly'
    | 'timeout'
    | 'renderCloseButtonLabel'
    | 'onDismiss'
    | 'transition'
    | 'hasShadow'
  >
>

type AlertWithKey = {
  key: number | string
} & Alert

export type UseAlerts = {
  alerts: Alert[]
  addAlert: (alert: Alert) => number
  removeAlert: (key: number) => void
  resetAlerts: () => void
}

const useAlerts = (initialValue: Alert[] = []): UseAlerts => {
  const removeAlert = useCallback((key) => {
    setAlerts((alerts) => alerts.filter((alert) => alert.key !== key))
  }, [])
  const handleAlertClosed = useCallback(
    (key) => {
      // For now this is the only thing to do:
      removeAlert(key)
    },
    [removeAlert]
  )

  const [alerts, setAlerts] = useState<AlertWithKey[]>(() =>
    prepareInitial(initialValue, handleAlertClosed)
  )

  const lastKey = useRef(0)

  const resetAlerts = useCallback(() => {
    setAlerts(() => [])
  }, [])

  const addAlert = useCallback(
    ({
      timeout = 5000,
      renderCloseButtonLabel = 'Close',
      onDismiss = () => {
        /* do nothing */
      },
      ...restProps
    }: Alert) => {
      const key = lastKey.current++
      const alert = {
        key,
        timeout,
        renderCloseButtonLabel,
        onDismiss: () => {
          handleAlertClosed(key)
          onDismiss()
        },
        ...restProps,
      }

      setAlerts((alerts) => [...alerts, alert])
      return key
    },
    [handleAlertClosed]
  )

  return {
    alerts,
    removeAlert,
    resetAlerts,
    addAlert,
  }
}

const prepareInitial = (initial: Alert[], handleAlertClosed): AlertWithKey[] =>
  initial.map((alert, i) => {
    const {
      variant,
      message,
      timeout = 5000,
      renderCloseButtonLabel = 'Close',
      onDismiss = () => {
        /* do nothing */
      },
    } = alert
    const key = `initial${i}`
    return {
      key,
      variant,
      message,
      timeout,
      renderCloseButtonLabel,
      onDismiss: () => {
        handleAlertClosed()
        onDismiss()
      },
    } as AlertWithKey
  })

export { useAlerts }

import { useRef } from 'react'
import { useVirtualizer } from '@tanstack/react-virtual'
import { DEFAULT_OPTION_HEIGHT_PX } from '../const'
import { useDebounce } from 'react-use'

const useVirtualization = (
  optionsCount: number,
  getItemKey: (index: number) => number | string
) => {
  const listContainerRef = useRef<HTMLElement>(null)
  const listRef = useRef<HTMLElement>(null)
  const listScrollRef = useRef<HTMLElement>(null)

  const virtualizer = useVirtualizer({
    count: optionsCount,
    getScrollElement: () => listContainerRef.current,
    estimateSize: () => DEFAULT_OPTION_HEIGHT_PX,
    getItemKey,
    overscan: 10,
  })

  const totalSize = virtualizer.getTotalSize()

  /**
   * Update the height of the list container when the total size of the list changes
   */
  useDebounce(
    () => {
      if (listRef.current) {
        const frame = requestAnimationFrame(() => {
          listScrollRef.current.style.height = `${totalSize || 0}px`
        })
        return () => cancelAnimationFrame(frame)
      }
    },
    10,
    [totalSize]
  )

  /**
   * Update the transform of the list when scrolling
   */
  if (listRef.current) {
    listRef.current.style.transform = `translateY(${
      virtualizer.getVirtualItems()?.[0]?.start || 0
    }px)`
  }

  /**
   * Measure the height of each option and group label currently rendered in the list
   */
  useDebounce(
    () => {
      if (listRef.current) {
        listRef.current
          .querySelectorAll('[role="option"][data-index]')
          .forEach((node) => {
            virtualizer.measureElement(node as HTMLElement)
          })
        listRef.current
          .querySelectorAll('[role="group"][data-index]')
          .forEach((node) => {
            // we need to measure the label for the group - element above the <ul>
            const previousElement = node.previousElementSibling
            if (previousElement) {
              // Copy data-index from node to previousElement
              const dataIndex = node.getAttribute('data-index')
              previousElement.setAttribute('data-index', dataIndex)

              // Remove data-index from node
              node.removeAttribute('data-index')

              virtualizer.measureElement(previousElement as HTMLElement)
            }
          })
      }
    },
    50,
    [
      virtualizer,
      listRef.current,
      virtualizer
        .getVirtualItems()
        .map((item) => item.index)
        .join(','),
    ]
  )

  const handleListRef = (node: HTMLElement) => {
    if (!node) return
    listRef.current = node
    listContainerRef.current = node.parentElement.parentElement
    listScrollRef.current = listRef.current.parentElement

    listScrollRef.current.style.height = `${virtualizer.getTotalSize() || 0}px`
    listScrollRef.current.style.position = 'relative'

    listRef.current.style.position = 'absolute'
    listRef.current.style.top = '0'
    listRef.current.style.left = '0'
    listRef.current.style.width = '100%'
  }

  const hasRenderedOptions = () => {
    return (
      listRef.current?.querySelectorAll('[role="option"][data-index]').length >
      0
    )
  }

  return { virtualizer, handleListRef, hasRenderedOptions }
}
export { useVirtualization }

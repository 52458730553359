import PropTypes from 'prop-types'
import { View, Alert } from '@instructure/ui'
import { Snackbar } from '../../elements/Snackbar'

/**
 * Component for showing stacked floating alerts
 */
const FloatingAlerts = ({ alerts = [] }) => {
  return (
    <Snackbar position={{ vertical: 'top', horizontal: 'center' }}>
      <View as={'div'} width={'34rem'}>
        {alerts.map(({ key, message, ...alertProps }) => (
          <Alert key={key} as={'div'} margin={'0 0 small 0'} {...alertProps}>
            {message}
          </Alert>
        ))}
      </View>
    </Snackbar>
  )
}

FloatingAlerts.propTypes = {
  /**
   * An array of alert objects. Supports all properties of Inst-UI <Alert/> component https://instructure.design/#Alert
   * Basic props are
   * {
   *   message,
   *   timeout,
   *   variant,
   *   renderCloseButtonLabel,
   *   onDismiss
   * }
   */
  alerts: PropTypes.array.isRequired,
}

export { FloatingAlerts }

import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Popover,
  CloseButton,
  IconButton,
  IconInfoLine,
  View,
} from '@instructure/ui'

import {parsePlacement} from'@instructure/ui-position'
import {safeCloneElement} from '@instructure/ui-react-utils'
import {debounce} from '@instructure/debounce'
import {getBoundingClientRect} from '@instructure/ui-dom-utils'
import {px} from '@instructure/ui-utils'

import {InfoPopoverTitle} from './InfoPopoverTitle'
import {InfoPopoverContent} from './InfoPopoverContent'

const InfoPopover = ({
  screenReaderLabel,
  children,
  renderTrigger = null,
  offsetX = 0,
  shouldCenterArrow = true,
  placement = 'bottom center',
  showOnOpen = false,
  color = 'primary',
  ...restProps
}) => {
  const dataQA = restProps['data-qa']

  const [isShowingContent, setIsShowingContent] = useState(showOnOpen)

  const contentEl = useRef(null)
  const targetEl = useRef(null)
  const popoverEl = useRef(null)

  /* contains current content placement - it could be automatically adjusted when there is not enough space for origin placement*/
  const adjustedPlacement = useRef(placement)
  const setAdjustedPlacement = (placement) => {
    adjustedPlacement.current = placement
  }

  const getAdjustedPlacement = () => {
    return adjustedPlacement.current
  }

  const adjustArrowToTriggerCenter = () => {
    if (contentEl.current) {
      let adjustedArrowOffsetX = 0
      let adjustedArrowOffsetY = 0

      const { arrowSize, arrowBorderWidth } =
        popoverEl.current._view.props.styles
      const originOffsetAmount = (px(arrowSize) + px(arrowBorderWidth)) * 2

      const targetWidth = getBoundingClientRect(targetEl.current).width
      const targetHeight = getBoundingClientRect(targetEl.current).height
      const contentOffsetX = targetWidth / 2 - originOffsetAmount
      const contentOffsetY = targetHeight / 2 - originOffsetAmount
      const arrowPosition = parsePlacement(getAdjustedPlacement())[1]

      if (arrowPosition === 'start') {
        adjustedArrowOffsetX = contentOffsetX
      } else if (arrowPosition === 'end') {
        adjustedArrowOffsetX = -contentOffsetX
      } else if (arrowPosition === 'top') {
        adjustedArrowOffsetY = contentOffsetY
      } else if (arrowPosition === 'bottom') {
        adjustedArrowOffsetY = -contentOffsetY
      }
      contentEl.current.style.left = adjustedArrowOffsetX + 'px'
      contentEl.current.style.top = adjustedArrowOffsetY + 'px'
    }
  }

  /**
   *
   * When browser window is resized - trigger dimensions could change and we need to adjust arrow offset
   * Component rerender is not triggered in this case
   */
  const handleWindowResize = debounce(
    () => {
      adjustArrowToTriggerCenter()
    },
    0,
    { leading: false, trailing: true }
  )

  useEffect(() => {
    window.removeEventListener('resize', handleWindowResize)

    if (isShowingContent && shouldCenterArrow) {
      window.addEventListener('resize', handleWindowResize)
    }

    return () => window.removeEventListener('resize', handleWindowResize)
    // disabled on initial eslint-plugin-react-hooks configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowingContent, shouldCenterArrow])

  const handlePositionChanged = ({ placement }) => {
    setAdjustedPlacement(placement)
    if (shouldCenterArrow) {
      adjustArrowToTriggerCenter()
    }
  }

  const handleCloseClick = (e) => {
    e.stopPropagation()
    setIsShowingContent(false)
  }

  const handleTriggerClick = (e) => {
    e.stopPropagation()
  }

  return (
    <Popover
      ref={popoverEl}
      color={color}
      contentRef={(el) => (contentEl.current = el)}
      renderTrigger={safeCloneElement(
        renderTrigger ?? (
          <IconButton
            size={'small'}
            renderIcon={IconInfoLine}
            withBackground={false}
            withBorder={false}
            screenReaderLabel="Toggle InfoPopover"
            onClick={handleTriggerClick}
            data-qa={dataQA}
          />
        ),
        { ref: (el) => (targetEl.current = el) }
      )}
      isShowingContent={isShowingContent}
      onShowContent={() => {
        setIsShowingContent(true)
      }}
      onHideContent={() => {
        setIsShowingContent(false)
      }}
      onPositionChanged={handlePositionChanged}
      onPositioned={handlePositionChanged}
      placement={placement}
      on="click"
      offsetX={offsetX}
      screenReaderLabel={screenReaderLabel}
      shouldContainFocus
      shouldReturnFocus
      shouldCloseOnDocumentClick
    >
      <CloseButton
        color={color}
        placement="end"
        offset="none"
        onClick={handleCloseClick}
        screenReaderLabel="Close"
      />
      <View padding="small" as="div" maxWidth={'22.5rem'} minWidth={'10rem'} onClick={(e) => e.stopPropagation()}>
        {children}
      </View>
    </Popover>
  )
}

InfoPopover.Title = InfoPopoverTitle
InfoPopover.Content= InfoPopoverContent

InfoPopover.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The placement of the content in relation to the trigger
   */
  placement: PropTypes.oneOf(['top', 'end', 'bottom', 'start', 'top start', 'start top', 'start center', 'start bottom', 'bottom start', 'bottom center', 'bottom end', 'end bottom', 'end center', 'end top', 'top end', 'top center', 'center end', 'center start', 'top stretch', 'bottom stretch', 'end stretch', 'start stretch', 'offscreen']),
  /**
   * An accessible label for the `<Popover />` content
   */
  screenReaderLabel: PropTypes.string,
  /**
   * The element that triggers the popover. Will replace the infoIcon button
   */
  renderTrigger: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * The `data-qa` tag to place on the `infoIcon` button. Has no
   * effect if you've overridden `renderTrigger`.
   */
  "data-qa": PropTypes.string,
  /**
   * Whether or not to show the content by default
   */
  showOnOpen: PropTypes.bool,
  /**
   * The horizontal offset for the positioned content
   */
  offsetX: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Whether or not the content should offset for arrow to always point to the trigger center
   */
  shouldCenterArrow: PropTypes.bool,
  /**
   * Color variant of the popover content
   */
  color: PropTypes.oneOf(['primary', 'primary-inverse']),
}

InfoPopover.defaultProps = {
  placement: 'top center',
  showOnOpen: false,
  shouldCenterArrow: true,
  renderTrigger: null,
  offsetX: 0,
}

export default InfoPopover
export { InfoPopover, InfoPopoverContent, InfoPopoverTitle }

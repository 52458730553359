import PropTypes from 'prop-types'
import { Text } from '@instructure/ui'

const InfoPopoverContent = ({children}) => {
  return (
      <Text size={"small"}>{children}</Text>
  )
}

InfoPopoverContent.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node
}

export default InfoPopoverContent
export {InfoPopoverContent}
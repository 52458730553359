import { Text, FileDrop, FormFieldGroup } from '@instructure/ui'
import { View } from '@instructure/ui-view'
import { IconUploadLine } from '@instructure/ui-icons'
import { useAddFormContext } from '../hooks/useAddForm'
import { useUploadFile } from '../hooks/useUploadFile'
import { Controller, ControllerRenderProps, FieldValues } from 'react-hook-form'
import { SelectedMaterialCsv } from './SelectedMaterialCsv'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'

export const MaterialCsvTemplate = () => {
  const { uploadFile, errors } = useUploadFile()

  const handleFileUpload = async (
    file: File,
    field: ControllerRenderProps<FieldValues, 'materialCsv'>
  ) => {
    await uploadFile({ file, validateOnly: true })
    field.onChange(file)
  }

  const { watch, reset } = useAddFormContext()
  const uploadedFile = watch('materialCsv')
  const fileSelected = !!uploadedFile
  const hasErrors = errors.length > 0

  const handleFileDelete = () => {
    reset()
  }

  return (
    <View>
      <Controller
        name="materialCsv"
        defaultValue=""
        render={({ field }) => (
          <View>
            <FormFieldGroup description={'Template'}>
              {fileSelected && !hasErrors && (
                <SelectedMaterialCsv
                  uploadedFile={uploadedFile}
                  onRemove={handleFileDelete}
                />
              )}

              {(!fileSelected || hasErrors) && (
                <FileDrop
                  width="100%"
                  accept=".csv"
                  messages={errors}
                  onDropAccepted={async (file) => {
                    const uploadedFile = file[0]
                    await handleFileUpload(uploadedFile as File, field)
                  }}
                  {...selectorAttrs('upload-material-csv-file')}
                  renderLabel={
                    <View
                      background={'secondary'}
                      as="div"
                      textAlign="center"
                      padding="x-large large"
                      borderColor={hasErrors ? 'danger' : 'secondary'}
                    >
                      <Text
                        color={'secondary'}
                        themeOverride={(_, { colors }) => ({
                          secondaryColor: colors.tiara,
                        })}
                      >
                        <IconUploadLine
                          width={'4.375rem'}
                          height={'4.375rem'}
                        />
                      </Text>

                      <View as={'div'} margin={'large 0 0 0'}>
                        <Text
                          lineHeight={'condensed'}
                          size={'x-large'}
                          color={'secondary'}
                        >
                          Drag a file here, or
                        </Text>
                      </View>

                      <View as={'div'} margin={'x-small 0 0 0'}>
                        <Text lineHeight={'default'} color="brand">
                          Choose a file to upload
                        </Text>
                      </View>
                    </View>
                  }
                />
              )}
            </FormFieldGroup>
          </View>
        )}
      />
    </View>
  )
}

import { AppProvider } from './providers/AppProvider'
import { Modal } from '@instructure/ui-modal'
import { View } from '@instructure/ui-view'
import { Heading } from '@instructure/ui-heading'
import { CloseButton } from '@instructure/ui-buttons'
import { MaterialShellModalProps, ShellProps } from './types'
import { selectorAttrs } from '../../../mc-ui/utils/selectorAttrs'
import { AddForm } from './AddForm'
import { Footer } from './Footer'
import { useAddFormContext } from './hooks/useAddForm'
import { useEffect } from 'react'

export const MaterialShellModal = ({
  open,
  onDismiss,
  ...config
}: MaterialShellModalProps) => {
  return (
    <AppProvider {...config}>
      <ShellModal open={open} onDismiss={onDismiss} />
    </AppProvider>
  )
}

const MODAL_VERTICAL_MARGIN = '8vh'
const HEADER_HEIGHTS = '5rem'
const FOOTER_HEIGHTS = '3.875rem'

const ShellModal = ({ open, onDismiss }: ShellProps) => {
  const { reset } = useAddFormContext()
  const handleCloseClick = () => {
    reset()
    onDismiss()
  }

  useEffect(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Modal
      open={open}
      label={'Add Assessment'}
      onDismiss={handleCloseClick}
      shouldCloseOnDocumentClick={false}
      {...selectorAttrs('add-assessment-modal')}
    >
      <Modal.Header>
        <CloseButton
          size={'medium'}
          onClick={handleCloseClick}
          screenReaderLabel="Close"
          placement="end"
          {...selectorAttrs('add-assessment-modal-close-button')}
        />
        <Heading>Add Assessment</Heading>
      </Modal.Header>
      <Modal.Body>
        <View
          as={'div'}
          css={{
            minHeight: `calc(100vh - 2*${MODAL_VERTICAL_MARGIN} - ${HEADER_HEIGHTS} - ${FOOTER_HEIGHTS})`,
          }}
          width={'24.5rem'}
        >
          <AddForm />
        </View>
      </Modal.Body>
      <Modal.Footer>
        <Footer onCancel={handleCloseClick} />
      </Modal.Footer>
    </Modal>
  )
}

import type {
  FetchResourceConfig,
  FetchResourceError,
  FetchResourceCodedError,
} from './types'

//@TODO: make this configurable
const defaultHeaders = (setDefaultContentType = true) => {
  const headers: HeadersInit = { 'X-CSRF-Token': window.CSRF_TOKEN }
  if (setDefaultContentType === true) {
    headers['Content-Type'] = 'application/json'
  }

  return headers
}

//@TODO: make this configurable
const defaultRequestParams: RequestInit = {
  credentials: 'same-origin',
}

export function fetchResource<ResponseType>(
  url: string,
  init: RequestInit = {},
  config: FetchResourceConfig = { setDefaultContentType: true },
): Promise<ResponseType> {
  const derivedInit: RequestInit = {
    ...defaultRequestParams,
    ...init,
    headers: {
      ...defaultHeaders(config.setDefaultContentType !== false),
      ...init.headers,
    },
  }

  return fetch(url, derivedInit).then((res) => {
    //@TODO: resolve as an error only when status>=500
    if (!res.ok) {
      const error = new Error(
        `Could not fetch ${url}, received ${res.status}`,
      ) as FetchResourceCodedError
      error.response = res
      throw error
    }
    return res.status === 204 ? undefined : res.json()
  })
}

// Safely extract a `Response` from a `FetchResourceError` if it is present, or
// `undefined` otherwise.
export function responseFromError(
  error: FetchResourceError
): Response | undefined {
  if (
    typeof error === 'object' &&
    'response' in error &&
    error.response instanceof Response
  )
    return error.response
  else return undefined
}

import { AbstractResource } from '../AbstractResource'

export type CurrentTeacherDTO = {
  id: number
  title: string
  firstName: string
  lastName: string
  hasOrIsNetwork: boolean
  bankProvider: boolean
  districtId: number
  frlAllowed: boolean
  desmosAllowed: boolean
  isPrincipal: boolean
  isDistadmin: boolean
  isStateadmin: boolean
  isContentmanager: boolean
  isContentreviewer: boolean
  isStandardsmanager: boolean
  isItemauthor: boolean
  isBenchmarkfacilitator: boolean
  isLimitedDistrictAdmin: boolean
  isDistrictAdminReports: boolean
  isLimitedSchoolAdmin: boolean
  isSchoolAdminReports: boolean
  isInstructionalCoachAdmin: boolean
  isInstructionalCoach: boolean
  isBenchmarkAuthor: boolean
  isContentApproval: boolean
  isBenchmarkReporting: boolean
}

class CurrentTeacherResource extends AbstractResource {
  _camelizeKeys = true
  _endpointUrl = '/current_teacher.js'
  _buildResourcePath = () => this._endpointUrl

  read(): Promise<CurrentTeacherDTO> {
    return super.read(null)
  }
}

export { CurrentTeacherResource }
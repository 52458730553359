import { ObjectiveDTO, SubjectDTO } from "../types/alignment"

export enum ResourceName {
  CLASS_OBJECTIVES = 'class_objectives',
  OBJECTIVE_SETS = 'objective_sets',
  OBJECTIVES = 'objectives',
  PATHWAYS = 'pathways',
  STATE_PATHWAY_SETS = 'state_pathway_sets',
  SUBJECTS = 'subjects',
  ALIGNMENT_SOURCE = 'alignment_source',
  IS_RESTRICTED_BY_DISTRICT = 'is_restricted_by_district',
}

export interface AlignmentParams {
  class_objective_id: number | string
  classroom_id: number | string
  curriculum_map_id: number | string
  pathway_id: number | string
  state_id: number | string
  subject_id: number | string
}

export type ObjectivesResponseDTO = {
  objectives: ObjectiveDTO[]
}

export type ObjectiveResponseDTO = {
  objective: ObjectiveDTO
}

export type SubjectsResponseDTO = {
  subjects: SubjectDTO[]
}

export type PathwayDTO = {
  id: number
  name: string
  custom: boolean
  subject_id: number
  state_id: number
}

export type StateDTO = {
  id: number
  code: string
  name: string
}

export type PathwaysResponseDTO = {
  pathways: PathwayDTO[]
  states: StateDTO[]
}

export type ClassObjectiveDTO = {
  id: number
  name: string
  state_id: number
  subject_id: number
}

export type ClassObjectivesResponseDTO = {
  class_objectives: ClassObjectiveDTO[]
}

export type ObjectiveSetsResponseDTO = {
  class_objectives: ClassObjectiveDTO[]
  classrooms: unknown[]
  curriculum_maps: unknown[]
}

export type StatePathwaySetsResponseDTO = {
  pathways: unknown
  pathways_restricted_by_district: boolean
}

export enum PrivacyLevel {
  PUBLIC = 'public',
  NETWORK = 'network',
  DISTRICT = 'district',
  SCHOOL = 'school',
  ME = 'me',
}

export enum AssessmentTypeEnum {
  Formative = 'formative',
  Benchmark = 'benchmark',
}

export type ClassroomDTO = {
  id: number
  name: string
  class_objective_id: number
  class_objective_name: string
  pathway_id: number
  pathway_name: string
  subject_id: number
  subject_name: string
}

export type CurriculumMapDTO = {
  id: number
  name: string
  class_objective_id: number
  class_objective_name: string
  pathway_id: number
  pathway_name: string
  subject_id: number
  subject_name: string
}

export type AlignmentSourceResponseDTO = {
  classrooms: ClassroomDTO[]
  curriculum_maps: CurriculumMapDTO[]
}

export type IsRestrictedByDistrictResponseDTO = {
  pathways_restricted_by_district: boolean
}

export type MaterialCsvUploadResultDTO = {
  success: boolean
  materialId?: number
  errors?: string[]
  material?: {
    id: number
    title: string
    scaleId: string
    targetDistrict: string
    distbenchmark: boolean
    subjectId: string
    subject: string
    pathwayId: string
    pathway: string
    classObjectiveId: string
    classObjective: string
    privacy: PrivacyLevel
  }
}

import { AbstractResource } from '../AbstractResource'
import { PrivacyLevel } from '../internal/types'
import { snakeCaseKeys, camelizeKeys } from '../../mc-sdk/case-utils'
import { Feature } from '../../mc-sdk/learnosity/types'
import { DefaultCutScores, Scale, CutScores, ObjectiveDTO } from '../types'
import { AlignmentSourceDTO } from './MaterialShellInitConfigResource'
import { ItemBankDTO } from '../item-banks/ItemBanks'
import { QuestionState } from '../questions/types'

export type MaterialDTO = {
  material: Material
  items: Item[]
  questions: Question[]
  validation?: PublishableStatus
  validatePublishable?: boolean
}

export type PublishableStatus = {
  publishable: boolean
  errors: string[]
}

export enum ItemType {
  ITEM = 'item',
  PART = 'part_division',
}

export enum QuestionTypes {
  MULTIPLE_CHOICE = 1,
  BUBBLE_SHEET = 3,
  TRUE_FALSE = 4,
  RUBRIC_CRITERIA = 5,
  MULTI_SELECT = 6,
  UNSUPPORTED = 300,
}

export enum ItemTag {
  UPDATED_ITEM = 'updated-item',
  ACKNOWLEDGED_UPDATED_ITEM = 'acknowledged-updated-item',
  RETIRED_ITEM = 'retired-item',
  OBJECTIVE_MISMATCH = 'objective-mismatch',
  ACKNOWLEDGED_NEW_VERSION = 'acknowledged-new-version',
  FAILED_CONVERSION = 'failed-conversion',
  PASSAGE_CONVERSION = 'passage-conversion',
  BELL_ERROR = 'bell-error',
  BELL_ERROR_ACKNOWLEDGED = 'bell-error-acknowledged',
  CAN_REALIGN = 'can-realign',
  CANNOT_REALIGN = 'cannot-realign',
  PASSAGE_REVOKED = 'passage-revoked',
  ITEM_BANK_REVOKED = 'item-bank-revoked',
}

export type Item = {
  type: ItemType
  id?: string
  questionId?: string
  objectiveId?: number
  objectiveName?: string
  itemType?: number
  answer?: Answer
  newQuestionVersionId?: string
  points?: number
  tags?: ItemTag[]
}

export type Answer =
  | MultipleChoiceAnswer
  | MultiSelectAnswer
  | TrueFalseAnswer
  | BubbleSheetRubricScoringAnswer
  | RubricCriteriaAnswer

export type MultipleChoiceAnswer = string // "A"
export type MultiSelectAnswer = string // "ABC" = A, B, and C are correct answers
export type TrueFalseAnswer = string // "A" = true, "B" = false
export type BubbleSheetRubricScoringAnswer = {
  a: string
  b: string
  c: string
  d: string
  e: string
} // Each string value should be a number or empty string
export type RubricCriteriaAnswer = { [key: number]: string } // Each string value should be a number or empty string

export enum SourceType {
  DOCUMENT = 'document',
  ITEM_BANK = 'item_bank',
  RAW = 'raw',
}

export enum AssessmentType {
  BENCHMARK = 'benchmark',
  FORMATIVE = 'formative',
}

export type Material = {
  id: number
  title: string
  description: string
  draft: boolean
  totalScore: number
  distbenchmark: boolean
  objectiveId: number
  isDistrictFormative: boolean
  districtApproved?: boolean
  districtHasNetwork?: boolean
  districtIsNetwork?: boolean
  createdAt: Date
  updatedAt: Date
  scale: Scale
  alignment: Alignment
  creationSource: CreationSource
  privacyLevel?: PrivacyLevel
  destinationDistrict: DestinationDistrict
  sendToConsumerDistrict?: boolean
  // sourceType name and values could change in the future
  // https://instructure.atlassian.net/browse/MCE-17938
  sourceType: SourceType
  defaultCutScores: DefaultCutScores
  cutScores: CutScores
  scaleId?: string | number
  isInAssessmentCollection: boolean
  options?: Options
}

export type Options = {
  disableDownload: '0' | '1'
  allowTeacherDelete: '0' | '1'
  copyrightFree: '0' | '1'
}

export type Alignment = {
  classObjective: ClassObjective
  classroom?: Classroom
  curriculumMap?: CurriculumMap
  subjects: Subject[]
  pathway: Pathway
  state: State
  objective?: Objective
}

export type CreationSource = {
  classroom?: Classroom
  curriculumMap?: CurriculumMap
}

export type AlignmentChoice = {
  classObjective?: Pick<ClassObjective, 'id'>
  classroom?: Pick<Classroom, 'id'>
  curriculumMap?: Pick<CurriculumMap, 'id'>
  subject: Pick<Subject, 'id'>
  pathway?: Pick<Pathway, 'id'>
}

export type Classroom = {
  id: number
  name: string
  alignment?: {
    subject: Subject
    pathway: Pathway
    state: State
    classObjective: ClassObjective
  }
}

export type CurriculumMap = {
  id: number
  name: string
  alignment?: {
    subject: Subject
    pathway: Pathway
    state: State
    classObjective: ClassObjective
  }
}

export type Pathway = {
  id: number
  name: string
  stateId: number
  subjectId: number
  custom: boolean
}

export type ClassObjective = {
  id: number
  name: string
  stateId: number
  subjectId: number
}

export type State = {
  id: number
  code: string
  name: string
}

export type Subject = {
  id: number
  name: string
}

export type Objective = {
  id: number
  name: string
  description?: string
}

export type DestinationDistrict = {
  id: string
  name: string
  status: string
  timezone: string
  state: State
  residentState: State
  scale?: Scale
}

export type Passage = {
  id: number | string
  path: string
  content: string
  lang: string
  title: string
  bank?: ItemBankDTO
  originBank?: ItemBankDTO | null
}

export type QuestionType = {
  typeId: number
  name: string
  printName: string
  source?: string
  autoGraded?: boolean
}

export type Question = {
  id: string
  title: string
  bankId: string
  bankName?: string
  state: QuestionState
  linkedQuestions?: Question[]
  jsonData: JSONData | null
  features?: Feature[]
  printable?: boolean
  clonable?: boolean
  gradecam?: boolean
  reserved?: boolean
  qtiXml: string | null
  points?: number
  passages?: Passage[]
  dok: number
  difficulty: number
  blooms: number
  lang: string
  questionType: QuestionType
  destinationBankId: string | null
  objectiveIds: number[]
  updatedAt: string | null
  importErrors?: string[]
  importQuizGroupId?: string
  linkedQuestionId?: string
  shuffleable?: boolean
  ownershipType?: string
  externalId?: string
  originQuestionId?: string
}

export type Date = string

// We purposefully want to keep this data in the format that will be passed between MC and Learnosity.
// This is because the Learnosity API uses both camelCase and snake_case keys. When we convert the JSON keys,
// we cannot un-convert them without creating special rules for each key that shouldn't be converted.
// See Learnosity Question Types API: https://reference.learnosity.com/questions-api/questiontypes
// See Learnosity Response Format API: https://reference.learnosity.com/questions-api/responses
export type JSONData = {
  stimulus?: string
  stimulus_list?: string[]
  template?: string
  stems?: string[]
  list?: string[]
  options?: Option[] | string[]
  tokenization?: string
  response_container?: unknown
  response_containers?: ResponseContainer[]
  instructor_stimulus?: string
  stimulus_review?: string
  possible_responses?: string[]
  type?: string
  validation?: {
    valid_response?: { score: number; value: (string | object)[] }
    scoring_type?: string
  }
  is_math?: boolean
  shuffle_options?: boolean
  metadata?: {
    distractor_rationale?: string
    distractor_rationale_response_level?: string[]
    acknowledgements?: string
    rubric: string
    rubric_points: number
  }
}

export type Option = {
  label: string
  value: string | undefined
}

export type ResponseContainer = {
  x: number
  y: number
  width: string
  height: string
}

export type CreateMaterialDTO = {
  title: string
  description: string
  sourceClassroom?: Pick<Classroom, 'id'>
  sourceCurriculumMap?: Pick<CurriculumMap, 'id'>
  sourceCsvAlignment?: AlignmentSourceDTO
  alignment: AlignmentChoice
  distbenchmark: boolean
  districtApproved?: boolean
  scale: Scale
  privacyLevel?: PrivacyLevel
  destinationDistrict: DestinationDistrict
  sourceType: SourceType
  materialCsv?: File
}

export type CreateMaterialWithQuestionsDTO = {
  title: string
  sourceClassroom: Pick<Classroom, 'id'>
  sourceType: SourceType
  alignment: AlignmentChoice
  questions: {
    questionId: number | string,
    objectiveId: number
  }[]
}

export type CloneReAlignMaterialDTO = {
  title: string
  alignment: AlignmentChoice
  distbenchmark: boolean
  scale: Pick<Scale, 'id'>
  privacyLevel: PrivacyLevel
  destinationDistrict: Pick<DestinationDistrict, 'id'>
}

export type CreateMaterialResponseDTO = {
  id: number
}

export type SaveMaterialDTOMaterial = Omit<
  MaterialDTO['material'],
  | 'id'
  | 'draft'
  | 'createdAt'
  | 'isDistrictFormative'
  | 'districtHasNetwork'
  | 'districtIsNetwork'
  | 'defaultCutScores'
  | 'sourceType'
  | 'creationSource'
  | 'sendToConsumerDistrict'
  | 'alignment'
  | 'scale'
  | 'destinationDistrict'
> & {
  alignment: Pick<MaterialDTO['material']['alignment'], 'objective'>
  scale: Omit<MaterialDTO['material']['scale'], 'name' | 'scaleLevels'>
  destinationDistrict: Omit<
    MaterialDTO['material']['destinationDistrict'],
    'name' | 'status' | 'timezone' | 'state' | 'residentState' | 'scale'
  >
}

export type SaveMaterialDTOQuestions = Array<
  Omit<
    MaterialDTO['questions'][0],
    | 'bankId'
    | 'gradecam'
    | 'importQuizGroupId'
    | 'shuffleable'
    | 'linkedQuestionId'
    | 'updatedAt'
    | 'qtiXml'
    | 'externalId'
    | 'state'
    | 'bankName'
    | 'ownershipType'
    | 'printable'
    | 'reserved'
    | 'clonable'
    | 'questionType'
  > & {
    questionType: Omit<
      Question['questionType'],
      'name' | 'printName' | 'source' | 'autoGraded'
    >
    // need to pass jsonData as string because Rails removes nulls from validation arrays
    // See https://guides.rubyonrails.org/security.html#unsafe-query-generation
    jsonData: string
  }
>

export type SaveMaterialDTO = Omit<MaterialDTO, 'material' | 'questions'> & {
  material: SaveMaterialDTOMaterial
  questions: SaveMaterialDTOQuestions
  // The sessionId is not optional: It must either be given (to validate the
  // request) or set null (to opt out of validation). Leaving undefined is invalid.
  sessionId: string | null
}

export type SaveMaterialResponseDTO = MaterialDTO

export type PublishMaterialResponseDTO = {
  created?: {
    url: string
  }
}

export type CloneMaterialResponseDTO = {
  material: {
    id: string
  }
}

export type RevertToDraftMaterialResponseDTO = {
  success: boolean
  message: string
}

export type ReleaseMaterialToConsumerResponseDTO = {
  success: boolean
}

// Safely camelize Material data keys while preserving Learnosity JSON data keys
export const camelizeMaterialKeys = (materialData) => {
  const camelizedMaterial: MaterialDTO = camelizeKeys(materialData)
  const questionsWithPreservedJsonData = camelizedMaterial.questions.map(
    (question, index) => ({
      ...question,
      jsonData: materialData.questions[index].json_data,
    })
  )
  return {
    ...camelizedMaterial,
    questions: questionsWithPreservedJsonData,
  } as MaterialDTO
}

// Safely snake_case Material data keys while preserving Learnosity JSON data keys
export const snakeCaseMaterialKeys = (materialData: {
  questions: {
    jsonData: JSONData
  }[]
}) => {
  const snakeCaseMaterial = snakeCaseKeys(materialData)
  const questionsWithPreservedJsonData = snakeCaseMaterial.questions.map(
    (question, index) => ({
      ...question,
      json_data: materialData.questions[index].jsonData,
    })
  )
  return {
    ...snakeCaseMaterial,
    questions: questionsWithPreservedJsonData,
  }
}

class MaterialResource extends AbstractResource {
  _endpointUrl = '/api/internal/materials'

  constructor(data: Partial<MaterialResource>) {
    super()
    Object.assign(this, data)
  }

  read(resourceId: number): Promise<MaterialDTO> {
    return super
      .readWithQuery(resourceId, {
        validate_publishable: true,
      })
      .then((data) => camelizeMaterialKeys(data))
  }

  create(data: CreateMaterialDTO): Promise<CreateMaterialResponseDTO> {
    return super.create(snakeCaseKeys(data))
  }

  createWithQuestions(
    data: CreateMaterialWithQuestionsDTO
  ): Promise<CreateMaterialResponseDTO> {
    return super.create(snakeCaseKeys(data))
  }

  put(
    data: SaveMaterialDTO,
    resourceId: number
  ): Promise<SaveMaterialResponseDTO> {
    const saveAndValidateData = {
      material: data.material,
      items: data.items,
      questions: data.questions,
      sessionId: data.sessionId,
      validatePublishable: true,
    }

    return super
      .put(snakeCaseMaterialKeys(saveAndValidateData), resourceId)
      .then((data) => camelizeMaterialKeys(data))
  }

  publish(materialId: number): Promise<PublishMaterialResponseDTO> {
    return this.fetchResource(
      `${this._buildResourcePath(materialId)}/publish`,
      {
        method: 'POST',
      }
    ).then((data) => camelizeKeys(data))
  }

  publishAndRemove(
    materialId: number | string
  ): Promise<PublishMaterialResponseDTO> {
    return this.fetchResource(
      `${this._buildResourcePath(materialId)}/publish_and_remove`,
      {
        method: 'POST',
      }
    ).then((data) => camelizeKeys(data))
  }

  clone(
    materialId: string,
    data?: CloneReAlignMaterialDTO
  ): Promise<CloneMaterialResponseDTO> {
    return this.fetchResource(`${this._buildResourcePath(materialId)}/clone`, {
      method: 'POST',
      body:
        data &&
        JSON.stringify(this._snakeCaseKeys ? snakeCaseKeys(data) : data),
    }).then((data) => camelizeKeys(data))
  }

  revertToDraft(
    materialId: number | string
  ): Promise<RevertToDraftMaterialResponseDTO> {
    return this.fetchResource(
      `${this._buildResourcePath(materialId)}/revert_to_draft`,
      {
        method: 'POST',
      }
    ).then((data) => camelizeKeys(data))
  }

  delete(resourceId, data?: Record<string, unknown>) {
    return this.fetchResource(`/materials/${resourceId}`, {
      method: 'DELETE',
      body: data && JSON.stringify(data),
    })
  }

  claimSession(materialId: number): Promise<{ sessionId: string }> {
    return this.fetchResource(
      `${this._buildResourcePath(materialId)}/claim_session`,
      {
        method: 'POST',
      }
    ).then((data) => camelizeKeys(data))
  }

  // These two methods are placeholders and will fail for now, they will be implemented
  // and connected to the correct endpoint(s) in a follow-up ticket
  // For now this allows the storybook to be built and the components to be tested
  releaseMaterialToConsumer(
    materialId: number | string
  ): Promise<ReleaseMaterialToConsumerResponseDTO> {
    return this.fetchResource(
      `/administration/materials/${materialId}/distribute`,
      {
        method: 'POST',
      }
    )
  }

  unreleaseMaterialToConsumer(
    materialId: number | string
  ): Promise<ReleaseMaterialToConsumerResponseDTO> {
    return this.fetchResource(
      `/administration/materials/${materialId}/undistribute`,
      {
        method: 'POST',
      }
    )
  }
}

export type { ObjectiveDTO }

export { MaterialResource }

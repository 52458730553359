import { View } from '@instructure/ui-view'
import { Flex, IconButton, Text } from '@instructure/ui'
import { IconEndSolid, IconMsExcelLine } from '@instructure/ui-icons'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'

export const SelectedMaterialCsv = ({
  uploadedFile,
  onRemove,
}: {
  uploadedFile: { name: string }
  onRemove?: () => void
}) => {
  return (
    <View
      shadow="resting"
      width={'100%'}
      borderRadius={'medium'}
      borderWidth={'small'}
      as={'div'}
    >
      <Flex as={'div'} width={'100%'} alignItems={'start'}>
        <Flex.Item padding={'x-small 0 x-small small'}>
          <IconMsExcelLine color={'success'} width={'1.25rem'} />
        </Flex.Item>
        <Flex.Item
          padding={'x-small 0 x-small x-small'}
          shouldGrow
          shouldShrink
        >
          <Text>{uploadedFile.name}</Text>
        </Flex.Item>
        <Flex.Item>
          <IconButton
            margin={'0 0 0 x-small'}
            withBorder={false}
            withBackground={false}
            screenReaderLabel={'Remove CSV file'}
            {...selectorAttrs('remove-csv-file')}
            onClick={onRemove}
          >
            <IconEndSolid color={'error'} />
          </IconButton>
        </Flex.Item>
      </Flex>
    </View>
  )
}

import { AbstractResource } from '../AbstractResource'
import {
  AlignmentParams,
  ClassObjectivesResponseDTO,
  ObjectiveSetsResponseDTO,
  ObjectivesResponseDTO,
  ObjectiveResponseDTO,
  PathwaysResponseDTO,
  ResourceName,
  StatePathwaySetsResponseDTO,
  SubjectsResponseDTO,
  AlignmentSourceResponseDTO,
  IsRestrictedByDistrictResponseDTO,
} from './types'

// This is a client-side mirror of AlignmentFilters to fetch the same info from
// the alignments API.
//
// Instead of using this resource directly, consider using the ChooseAlignments app.
//
class Alignments extends AbstractResource {
  _endpointUrl = '/api/internal/alignments'

  subjectID: number | string
  pathwayID: number | string
  classObjectiveID: number | string
  classroomID: number | string
  curriculumMapID: number | string
  stateID: number | string

  constructor(data: Partial<Alignments>) {
    super()
    Object.assign(this, data)
  }

  subjects(): Promise<SubjectsResponseDTO> {
    return this.fetchResource(this._buildResourcePath(ResourceName.SUBJECTS))
  }

  pathways(): Promise<PathwaysResponseDTO> {
    return this._fetchQuery(ResourceName.PATHWAYS, {
      subject_id: this.subjectID,
    })
  }

  classObjectives(): Promise<ClassObjectivesResponseDTO> {
    return this._fetchQuery(ResourceName.CLASS_OBJECTIVES, {
      subject_id: this.subjectID,
      pathway_id: this.pathwayID,
    })
  }

  objectiveSets(): Promise<ObjectiveSetsResponseDTO> {
    return this._fetchQuery(ResourceName.OBJECTIVE_SETS, {
      subject_id: this.subjectID,
      pathway_id: this.pathwayID,
    })
  }

  objectives(): Promise<ObjectivesResponseDTO> {
    return this._fetchQuery(ResourceName.OBJECTIVES, {
      class_objective_id: this.classObjectiveID,
      classroom_id: this.classroomID,
      curriculum_map_id: this.curriculumMapID,
    })
  }

  objective(objectiveId: number | string): Promise<ObjectiveResponseDTO> {
    return this.fetchResource(
      `${this._endpointUrl}/${ResourceName.OBJECTIVES}/${objectiveId}`
    )
  }

  statePathwaySets(): Promise<StatePathwaySetsResponseDTO> {
    return this._fetchQuery(ResourceName.STATE_PATHWAY_SETS, {
      subject_id: this.subjectID,
      state_id: this.stateID,
    })
  }

  alignmentSource(): Promise<AlignmentSourceResponseDTO> {
    return this.fetchResource(
      this._buildResourcePath(ResourceName.ALIGNMENT_SOURCE)
    )
  }

  isRestrictedByDistrict(): Promise<IsRestrictedByDistrictResponseDTO> {
    return this.fetchResource(
      this._buildResourcePath(ResourceName.IS_RESTRICTED_BY_DISTRICT)
    )
  }

  _fetchQuery(resourceName: ResourceName, params: Partial<AlignmentParams>) {
    return this.fetchResource(
      this._buildResourcePath(query(resourceName, params))
    )
  }
}

// Internal helper to generate query params in a nice way. Note that this is not
// a complete solution, as we're just doing string substitutions; it only works
// in this context because the only things we're dealing with are numeric ids.
const query = (
  resourceName: ResourceName,
  params: Partial<AlignmentParams>
) => {
  if (!params) return resourceName

  const queryString = Object.entries(params)
    .filter(([, val]) => val != null)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')

  return `${resourceName}?${queryString}`
}

export { Alignments }

import { AbstractResource } from '../AbstractResource'
import { PrivacyLevel, AssessmentTypeEnum } from '../internal/types'
import { camelizeKeys, snakeCaseKeys } from '../../mc-sdk/case-utils'
import {
  Classroom,
  CurriculumMap,
  SourceType,
  Subject,
  Pathway,
  ClassObjective,
  State,
} from './MaterialResource'
import { PrivacyOptionDTO } from './MaterialPrivacyOptionsResource'

export interface ScaleDTO {
  id: string
  name: string
}

export interface PathwayDTO {
  id: number
  subjectId: number
}

export interface AlignmentSourceDTO {
  source?: string
  id: number
  name: string
  alignment?: {
    subject: Subject
    pathway: Pathway
    state?: State
    classObjective: ClassObjective
  }
}

export interface AlignmentSourcesDTO {
  classrooms: Array<Classroom>
  curriculumMaps: Array<CurriculumMap>
}

export interface ShellMaterialConfigDTO {
  canAuthorDistBenchmark: boolean
  canProvideContent: boolean
  scales: Array<ScaleDTO>
  defaultScaleId: string
  alignmentSources: AlignmentSourcesDTO
  defaultAlignmentSource: AlignmentSourceDTO
  defaultPathways: Array<PathwayDTO>
  districtHasNetwork: boolean
  districtIsNetwork: boolean
  defaultMaterialPrivacy: PrivacyLevel
  defaultMapPrivacy: PrivacyLevel
  defaultAssessmentType: AssessmentTypeEnum
  defaultSource: SourceType
  canUploadMaterialCsv: boolean
  defaultPrivacyOptions: Array<PrivacyOptionDTO>
  defaultPrivacyOptionsTimestamp: number
  defaultContentConsumerId: string
}

export interface MaterialCreateContextDTO {
  classroomId?: string | number
  curriculumMapId?: string
}

class MaterialShellInitConfigResource extends AbstractResource {
  _endpointUrl = '/api/internal/materials/new'

  getConfig(
    context: MaterialCreateContextDTO
  ): Promise<ShellMaterialConfigDTO> {
    return this.list(snakeCaseKeys(context)).then((data) => camelizeKeys(data))
  }
}

export { MaterialShellInitConfigResource }

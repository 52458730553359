import { useQuery } from '@tanstack/react-query'
import {
  MaterialPrivacyOptionsResource,
  PrivacyOptionDTO,
} from '../../../mc-resources/materials/MaterialPrivacyOptionsResource'
import { Alignments } from '../../../mc-resources/internal/Alignments'

export const usePrivacyOptions = (
  assessmentType: string,
  source: string,
  fileIsAttached: boolean,
  initialPrivacyOptions: Array<PrivacyOptionDTO>,
  initialDataLastUpdatedAt: number,
  enabled: boolean
) => {
  const materialPrivacyOptionsResource =
    MaterialPrivacyOptionsResource.createInstance()

  const privacyOptionsDeps = {
    docType: assessmentType,
    source: source,
    isFileAttached: fileIsAttached,
  }

  const {
    data: privacyOptions,
    isFetching: isPrivacyOptionsFetching,
    dataUpdatedAt,
  } = useQuery(
    ['material_privacy_options', assessmentType, source, fileIsAttached],
    () => materialPrivacyOptionsResource.list(privacyOptionsDeps),
    {
      initialData: initialPrivacyOptions,
      // initialDataUpdatedAt is a hack to make initialData work here as the Privacy component's parent makes it mount with every update (don't copy it)
      initialDataUpdatedAt: initialDataLastUpdatedAt,
      staleTime: 1000,
      enabled: enabled,
    }
  )

  return {
    privacyOptions,
    privacyOptionsUpdatedAt: dataUpdatedAt,
    isPrivacyOptionsFetching,
  }
}

export const useObjective = (
  objectiveId: string | number,
  { enabled = true }
) => {
  const alignmentsResource = Alignments.createInstance()

  return useQuery({
    queryKey: ['alignments', 'objectives', objectiveId],
    queryFn: () => alignmentsResource.objective(objectiveId),
    staleTime: Infinity,
    enabled,
  })
}

import { Tooltip } from '@instructure/ui'
import { IconApprovedStar } from 'mc-icons'

interface DistrictApprovedBadgeProps {
  isDistrictApproved: boolean
}

export const DistrictApprovedBadge = ({
  isDistrictApproved,
}: DistrictApprovedBadgeProps) => {
  return isDistrictApproved ? (
    <>
      <Tooltip renderTip="District Approved" placement="top">
        <IconApprovedStar color="alert" inline={false} />
      </Tooltip>
    </>
  ) : (
    <></>
  )
}

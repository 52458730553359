import { useController } from 'react-hook-form'

import type { ChangeEvent } from 'react'
import type { UseControllerProps, Path, FieldPath } from 'react-hook-form'

type UseRadioGroupProps<
  TFormValues,
  TName extends Path<TFormValues>
> = UseControllerProps<TFormValues, TName> & {
  onChange?: (
    event: ChangeEvent<HTMLInputElement>,
    value: string | number | boolean | null
  ) => void
  valueAsNumber?: boolean
}

/**
 * This hook is used to create a radio group with react-hook-form
 */
export function useRadioGroup<
  TFormValues,
  TName extends Path<TFormValues> = FieldPath<TFormValues>
>({
  control,
  name,
  defaultValue,
  onChange,
  valueAsNumber = false,
}: UseRadioGroupProps<TFormValues, TName>) {
  const {
    field: { value, onChange: hookFormOnChange, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
  })

  const handleOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string | number | boolean | null
  ) => {
    let transformedValue = value
    // TODO: remove after https://instructure.atlassian.net/browse/INSTUI-3769 is resolved and we update instui
    if (valueAsNumber) {
      transformedValue = value === '' || value === null ? null : +value
    }

    hookFormOnChange(transformedValue)
    onChange?.(event, transformedValue)
  }

  const transformedValue = valueAsNumber && value === null ? '' : value

  return {
    ...inputProps,
    defaultValue,
    value: transformedValue,
    onChange: handleOnChange,
  }
}

import { AbstractResource } from '../AbstractResource'
import { PrivacyLevel } from '../internal/types'

export interface PrivacyOptionDTO {
  value: PrivacyLevel
  label: string
}

class MaterialPrivacyOptionsResource extends AbstractResource {
  _endpointUrl = '/api/internal/privacy_options/material_options'
  _camelizeKeys = true
  _snakeCaseKeys = true

  list: (query) => Promise<PrivacyOptionDTO[]>
}

export { MaterialPrivacyOptionsResource }

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useController } from 'react-hook-form'
import {
  Text,
  View,
  SimpleSelect,
  Flex,
  IconButton,
  IconXSolid,
  TruncateText,
  ScreenReaderContent,
  Tooltip,
  Button,
  Drilldown,
  FormFieldGroup,
  IconTargetLine,
} from '@instructure/ui'
import {
  useSubjectsQuery,
  usePathwaysQuery,
  useClassObjectivesQuery,
  useShellContentConsumersResource,
} from '../hooks/queries'
import { useConfig } from '../contexts/ConfigContext'
import { useAddFormContext } from '../hooks/useAddForm'
import { AlignmentProps } from '../types'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'
import {
  EasySelect,
  type GroupBase,
} from '../../../../mc-ui/collections/EasySelect'
import {
  ClassObjectiveDTO,
  PathwayDTO,
} from '../../../../mc-resources/internal/types'
import { useIsRestrictedByDistrict } from 'mc-app/curriculum-map/hooks/useIsRestrictedByDistrict'
import { DistrictApprovedBadge } from 'mc-app/curriculum-map/components/AlignmentFieldsSelector/AlignmentFields/DistrictApprovedBadge'
import { RequiredLabel } from 'mc-app/curriculum-map/components/RequiredLabel'

type AlignmentSource = {
  source?: string
  id: number
  name: string
  alignment?: {
    subject: {
      id: number
      name: string
    }
    state?: {
      id: number
      name: string
      code: string
    }
    pathway: {
      id: number
      name: string
    }
    classObjective: {
      id: number
      name: string
    }
  }
}

type Option = {
  value: number
  label: string
}

type PathwayGroup = GroupBase<Option>

type CreatePathwayOptionArgs = Pick<PathwayDTO, 'id' | 'name'> & {
  code: string
}

const emptyAlignmentSource: AlignmentSource = {
  source: '',
  id: null,
  name: '',
  alignment: {
    subject: {
      id: null,
      name: '',
    },
    state: {
      id: null,
      name: '',
      code: '',
    },
    pathway: {
      id: null,
      name: '',
    },
    classObjective: {
      id: null,
      name: '',
    },
  },
}

const Alignment = ({
  alignmentSources,
  defaultPathways,
  defaultAlignmentSource = null,
  disabled = false,
}: AlignmentProps) => {
  const { classroomId, mapId } = useConfig()
  const isRestrictedByDistrict = useIsRestrictedByDistrict()

  const { field: fieldSubjectId } = useController({
    name: 'alignment.subject.id',
    defaultValue: defaultAlignmentSource?.alignment.subject.id || null,
    rules: { required: true },
  })
  const { field: fieldPathwayId } = useController({
    name: 'alignment.pathway.id',
    defaultValue: defaultAlignmentSource?.alignment.pathway.id || null,
    rules: { required: true },
  })
  const { field: fieldClassObjectiveId } = useController({
    name: 'alignment.classObjective.id',
    defaultValue: defaultAlignmentSource?.alignment.classObjective.id || null,
    rules: { required: true },
  })
  const { field: fieldAlignmentClassroomId } = useController({
    name: 'alignment.classroom.id',
    defaultValue: classroomId ?? null,
  })
  const { field: fieldAlignmentCurriculumMapId } = useController({
    name: 'alignment.curriculumMap.id',
    defaultValue: mapId ?? null,
  })

  const { data: districts, isLoading: isLoadingContentConsumers } =
    useShellContentConsumersResource()
  const { watch } = useAddFormContext()
  const districtId = watch('destinationDistrict.id')

  const [defaultPathway, setDefaultPathway] = useState(false)
  const sourceCsvAlignment = watch('sourceCsvAlignment')
  const [isAlignmentSource, setIsAlignmentSource] = useState(
    classroomId || mapId ? true : false
  )

  const [selectedAlignmentSource, setSelectedAlignmentSource] =
    useState<AlignmentSource>(
      classroomId || mapId
        ? {
            ...defaultAlignmentSource,
            source: classroomId ? 'classroom' : 'curriculum_map',
          }
        : {
            ...emptyAlignmentSource,
          }
    )
  const readOnly = !!(sourceCsvAlignment || classroomId || mapId)

  const effectiveAlignmentSource = sourceCsvAlignment || selectedAlignmentSource

  const setPathwayTeacherSettings = useCallback(
    (subjectId) => {
      if (!subjectId) return
      const foundPathway = defaultPathways.find(
        (element) => element.subjectId === subjectId
      )

      if (foundPathway && defaultPathway) {
        fieldPathwayId.onChange(foundPathway.id)
        setDefaultPathway(false)
      }
    },
    [defaultPathways, setDefaultPathway, defaultPathway, fieldPathwayId]
  )

  const setPathwayDistrictSettings = useCallback(
    (subjectId) => {
      if (!districtId || !subjectId || isLoadingContentConsumers) return null
      const foundPathway = districts
        .find((element) => element.id === districtId)
        ?.pathways.find((element) => element.subjectId === subjectId)

      if (foundPathway && defaultPathway) {
        fieldPathwayId.onChange(foundPathway.id)
        setDefaultPathway(false)
        return true
      }
      return null
    },
    [
      districtId,
      districts,
      isLoadingContentConsumers,
      setDefaultPathway,
      defaultPathway,
      fieldPathwayId,
    ]
  )

  useEffect(() => {
    setPathwayDistrictSettings(fieldSubjectId.value) ??
      setPathwayTeacherSettings(fieldSubjectId.value)
  }, [
    districtId,
    fieldSubjectId,
    setPathwayDistrictSettings,
    setPathwayTeacherSettings,
  ])

  const { subjects, isSubjectsFetching } = useSubjectsQuery(!isAlignmentSource)

  const { pathways, isPathwaysFetching } = usePathwaysQuery(
    fieldSubjectId.value,
    !isAlignmentSource
  )

  // When alignment.pathway.id is set from outside, check if value exists in pathways
  const foundPathway = pathways?.find((element) =>
    element.pathways?.find((el) => el.id == fieldPathwayId.value)
  )

  // When Pathway is not available, set default from Core Settings
  useEffect(() => {
    if (
      defaultAlignmentSource === null &&
      !isPathwaysFetching &&
      !foundPathway &&
      fieldPathwayId.value
    ) {
      setDefaultPathway(true)
    }
  }, [
    defaultAlignmentSource,
    isPathwaysFetching,
    foundPathway,
    fieldPathwayId,
    setDefaultPathway,
  ])

  const { classObjectives, isClassObjectivesFetching } =
    useClassObjectivesQuery(
      fieldSubjectId.value,
      foundPathway ? fieldPathwayId.value : null,
      !isAlignmentSource
    )

  const handleSubjectChange = (e, { value }) => {
    fieldClassObjectiveId.onChange(null)
    fieldPathwayId.onChange(null)
    fieldSubjectId.onChange(value)
    setDefaultPathway(true)
  }

  const handlePathwayChange = (value: number) => {
    fieldClassObjectiveId.onChange(null)
    fieldPathwayId.onChange(value)
  }

  const handleClassObjectiveChange = (value: number) => {
    fieldClassObjectiveId.onChange(value)
  }

  const handleAlignmentSourceSelectCMap = (id: number) => {
    setIsAlignmentSource(true)

    const cmap = alignmentSources.curriculumMaps.find((c) => c.id == id)
    fieldAlignmentClassroomId.onChange(null)
    fieldAlignmentCurriculumMapId.onChange(+cmap.id)
    fieldSubjectId.onChange(+cmap.alignment.subject.id)
    fieldPathwayId.onChange(+cmap.alignment.pathway.id)
    fieldClassObjectiveId.onChange(+cmap.alignment.classObjective.id)

    setSelectedAlignmentSource({ source: 'curriculum_map', ...cmap })
  }

  const handleAlignmentSourceSelectTracker = (id: AlignmentSource['id']) => {
    setIsAlignmentSource(true)

    const classroom = alignmentSources.classrooms.find((c) => c.id == id)
    fieldAlignmentClassroomId.onChange(+classroom.id)
    fieldAlignmentCurriculumMapId.onChange(null)
    fieldSubjectId.onChange(+classroom.alignment.subject.id)
    fieldPathwayId.onChange(+classroom.alignment.pathway.id)
    fieldClassObjectiveId.onChange(+classroom.alignment.classObjective.id)

    setSelectedAlignmentSource({ source: 'classroom', ...classroom })
  }

  const handleAlignmentSourceRemove = () => {
    fieldClassObjectiveId.onChange(null)
    fieldPathwayId.onChange(null)
    fieldSubjectId.onChange(null)

    setIsAlignmentSource(false)

    fieldAlignmentClassroomId.onChange(null)
    fieldAlignmentCurriculumMapId.onChange(null)

    setSelectedAlignmentSource({ ...emptyAlignmentSource })
  }

  const createPathwayOption = ({
    code,
    id,
    name,
  }: CreatePathwayOptionArgs): Option => ({
    value: id,
    label: `${code}: ${name}`,
  })

  const pathwayMap = useMemo(() => {
    const map = new Map<number, PathwayDTO & { code: string }>()
    if (!pathways) return map

    pathways.forEach((pathwayGroup) => {
      const { code, pathways } = pathwayGroup
      pathways.forEach((pathway) =>
        map.set(pathway.id, {
          ...pathway,
          code,
        })
      )
    })

    return map
  }, [pathways])

  const pathwayOptions = useMemo(() => {
    return (
      pathways?.map(({ code, name, pathways }) => {
        const pathwayOptions = pathways.map(({ id, name }) =>
          createPathwayOption({ code, id, name })
        )

        return { options: pathwayOptions, label: name } as PathwayGroup
      }) || []
    )
  }, [pathways])

  const getOptionFromPathwayId = (id: number) => {
    const pathway = pathwayMap.get(id)
    if (!pathway) return null

    return createPathwayOption(pathway)
  }

  const classObjectiveMap = useMemo(() => {
    const map = new Map<number, ClassObjectiveDTO>()
    if (!classObjectives) return map

    classObjectives.forEach((classObjective) => {
      map.set(classObjective.id, classObjective)
    })

    return map
  }, [classObjectives])

  const createClassObjectiveOption = ({
    id,
    name,
  }: Pick<ClassObjectiveDTO, 'id' | 'name'>) =>
    ({ value: id, label: name } as Option)

  const classObjectivesOptions = useMemo(() => {
    return classObjectives?.map(createClassObjectiveOption) || []
  }, [classObjectives])

  const getOptionFromClassObjectiveId = (id: number) => {
    const classObjective = classObjectiveMap.get(id)
    if (!classObjective) return null

    return createClassObjectiveOption(classObjective)
  }

  const alignmentSourceMenu = (
    <Drilldown
      shouldHideOnSelect={true}
      rootPageId="root"
      width="20rem"
      maxHeight="30rem"
      onToggle={(ev, { shown, goToPage }) => {
        if (shown && !isAlignmentSource) {
          goToPage('root')
        }
      }}
      trigger={
        <Tooltip
          renderTip="Alignment Source"
          on={['hover', 'focus']}
          placement="top"
        >
          <Button
            disabled={disabled}
            renderIcon={<IconTargetLine />}
            {...selectorAttrs('add-assessment-modal-alignment-source-btn')}
          >
            Alignment Source
          </Button>
        </Tooltip>
      }
    >
      <Drilldown.Page id="root">
        <Drilldown.Option
          id="FromCMap"
          subPageId="FromCMap"
          {...selectorAttrs('add-assessment-modal-alignment-from--cmap')}
        >
          From a Curriculum Map
        </Drilldown.Option>

        <Drilldown.Option
          id="FromTracker"
          subPageId="FromTracker"
          {...selectorAttrs('add-assessment-modal-alignment-from--tracker')}
        >
          From a Tracker
        </Drilldown.Option>
      </Drilldown.Page>

      <Drilldown.Page id="FromCMap" renderTitle="From a Curriculum Map">
        <Drilldown.Group id="FromCMapGroup" selectableType="single">
          {alignmentSources?.curriculumMaps.map(({ id, name }) => (
            <Drilldown.Option
              id={`${id}`}
              key={id}
              selected={
                selectedAlignmentSource?.source === 'curriculum_map' &&
                selectedAlignmentSource.id === id
                  ? true
                  : false
              }
              onOptionClick={() => handleAlignmentSourceSelectCMap(id)}
              {...selectorAttrs(
                `add-assessment-modal-alignment-from-cmap--${id}`
              )}
            >
              {name}
            </Drilldown.Option>
          ))}
        </Drilldown.Group>
      </Drilldown.Page>

      <Drilldown.Page id="FromTracker" renderTitle="From a Tracker">
        <Drilldown.Group id="FromTrackerGroup" selectableType="single">
          {alignmentSources?.classrooms.map(({ id, name }) => (
            <Drilldown.Option
              id={`${id}`}
              key={id}
              selected={
                selectedAlignmentSource?.source === 'classroom' &&
                selectedAlignmentSource.id === id
                  ? true
                  : false
              }
              onOptionClick={() => handleAlignmentSourceSelectTracker(id)}
              {...selectorAttrs(
                `add-assessment-modal-alignment-from-tracker--${id}`
              )}
            >
              {name}
            </Drilldown.Option>
          ))}
        </Drilldown.Group>
      </Drilldown.Page>
    </Drilldown>
  )

  const alignmentSourceSelected = (
    <View as="div">
      <Flex direction="column" margin="0 0 small 0">
        <Flex.Item>
          <Text weight="bold">Selected Source</Text>
        </Flex.Item>

        <Flex.Item>
          <Flex direction="row" width="100%">
            <Flex.Item shouldShrink shouldGrow>
              <Text>
                <TruncateText maxLines={1}>
                  {selectedAlignmentSource.name}{' '}
                  {selectedAlignmentSource.source === 'classroom'
                    ? 'Tracker'
                    : 'Map'}
                </TruncateText>
              </Text>
            </Flex.Item>

            <Flex.Item>
              <IconButton
                withBackground={false}
                withBorder={false}
                color="danger"
                screenReaderLabel="Remove Selected Source"
                margin="xx-small"
                onClick={handleAlignmentSourceRemove}
                disabled={disabled}
                {...selectorAttrs(
                  'add-assessment-modal-remove-selected-source-btn'
                )}
              >
                <IconXSolid />
              </IconButton>
            </Flex.Item>
          </Flex>
        </Flex.Item>
      </Flex>

      <View as="div" background="secondary">
        <Flex direction="column">
          <Flex.Item padding="small small xx-small small">
            <Text>{selectedAlignmentSource.alignment.subject.name}</Text>
          </Flex.Item>
          <Flex.Item padding="xx-small small">
            <Text>{selectedAlignmentSource.alignment.pathway.name}</Text>
          </Flex.Item>
          <Flex.Item padding="xx-small small small">
            <Text>{selectedAlignmentSource.alignment.classObjective.name}</Text>
          </Flex.Item>
        </Flex>
      </View>
    </View>
  )

  const alignmentSourceReadOnly = (
    <View as="div">
      <Flex direction="column" margin="0 0 small 0">
        <Flex.Item>
          <Text weight="bold">Alignment</Text>
        </Flex.Item>
      </Flex>
      <View as="div" background="secondary">
        <Flex direction="column">
          {!sourceCsvAlignment && (
            <Flex.Item padding="small small xx-small small">
              <Text>
                {effectiveAlignmentSource.name}{' '}
                {classroomId ? 'Tracker' : 'Map'}
              </Text>
            </Flex.Item>
          )}
          <Flex.Item padding="xx-small small">
            <Text>{effectiveAlignmentSource.alignment.subject.name}</Text>
          </Flex.Item>
          <Flex.Item padding="xx-small small">
            <Text>
              {effectiveAlignmentSource.alignment.state?.code.concat(': ')}
              {effectiveAlignmentSource.alignment.pathway.name}
            </Text>
          </Flex.Item>
          <Flex.Item padding="xx-small small small">
            <Text>
              {effectiveAlignmentSource.alignment.classObjective.name}
            </Text>
          </Flex.Item>
        </Flex>
      </View>
    </View>
  )

  return (
    <>
      {readOnly && (
        <FormFieldGroup
          description={<ScreenReaderContent>Alignment</ScreenReaderContent>}
        >
          {alignmentSourceReadOnly}
        </FormFieldGroup>
      )}

      {!readOnly && (
        <FormFieldGroup
          description={<ScreenReaderContent>Alignment</ScreenReaderContent>}
        >
          {alignmentSourceMenu}
          {isAlignmentSource && alignmentSourceSelected}

          {!isAlignmentSource && (
            <SimpleSelect
              key={isSubjectsFetching ? 'loading subjects' : 'subjects'}
              renderLabel={
                <>
                  Subject <Text color="danger">*</Text>
                </>
              }
              placeholder={'Select Subject'}
              {...fieldSubjectId}
              value={fieldSubjectId.value ?? -1}
              onChange={handleSubjectChange}
              interaction={disabled ? 'disabled' : 'enabled'}
              {...selectorAttrs(
                'add-assessment-modal-alignment-subject-dropdown'
              )}
            >
              {subjects?.map(({ id, name }) => {
                return (
                  <SimpleSelect.Option
                    id={`${id}`}
                    key={id}
                    value={id}
                    {...selectorAttrs(
                      `add-assessment-modal-alignment-subject--${id}`
                    )}
                  >
                    {name}
                  </SimpleSelect.Option>
                )
              })}
            </SimpleSelect>
          )}

          {!isAlignmentSource && (
            <EasySelect
              key={isPathwaysFetching ? 'loading pathways' : 'pathways'}
              renderLabel={
                <Flex alignItems="center" gap="xxx-small">
                  <DistrictApprovedBadge
                    isDistrictApproved={isRestrictedByDistrict}
                  />
                  {RequiredLabel('Core')}
                </Flex>
              }
              placeholder={'Select Core'}
              options={pathwayOptions}
              value={getOptionFromPathwayId(fieldPathwayId.value)}
              onChange={(selectedOption) => {
                const { value: pathwayId } = selectedOption
                handlePathwayChange(pathwayId)
              }}
              interaction={disabled ? 'disabled' : 'enabled'}
              {...selectorAttrs('add-assessment-modal-alignment-core-dropdown')}
            />
          )}

          {!isAlignmentSource && (
            <EasySelect
              key={
                isClassObjectivesFetching
                  ? 'loading classObjectives'
                  : 'classObjectives'
              }
              renderLabel={
                <>
                  Class <Text color="danger">*</Text>
                </>
              }
              placeholder={'Select Class'}
              options={classObjectivesOptions}
              value={getOptionFromClassObjectiveId(fieldClassObjectiveId.value)}
              onChange={(selectedOption) => {
                const { value: classObjectiveId } = selectedOption
                handleClassObjectiveChange(classObjectiveId)
              }}
              interaction={disabled ? 'disabled' : 'enabled'}
              {...selectorAttrs(
                'add-assessment-modal-alignment-class-dropdown'
              )}
            />
          )}
        </FormFieldGroup>
      )}
    </>
  )
}
export { Alignment }

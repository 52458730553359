import { AbstractResource } from '../AbstractResource'

class MaterialObjectiveQueryResource extends AbstractResource {
  constructor(materialModalPath) {
    super()
    this._endpointUrl = `${materialModalPath}/objectives`
  }

  buildResourceUri = (objectiveId, params) => {
    const path = this._buildResourcePath(objectiveId)
    const queryStr = this._buildQueryString(params)
    return `${path}${queryStr}`
  }
}

export { MaterialObjectiveQueryResource }

import { useQuery } from '@tanstack/react-query'

import { Alignments } from '../../../mc-resources/internal/Alignments'

export const useIsRestrictedByDistrict = (options = {}) => {
  const { data: isRestrictedByDistrict, isFetching } = useQuery({
    queryKey: ['isRestrictedByDistrict'],
    queryFn: async () => {
      const aligmentsResource = Alignments.createInstance()
      const result = await aligmentsResource.isRestrictedByDistrict()
      return result.pathways_restricted_by_district
    },
    ...options,
  })

  return isFetching ? false : isRestrictedByDistrict
}

import { createContext, ReactNode, useContext } from 'react'
import { ConfigProps, FormValues } from '../types'

type Config = ConfigProps & { defaultValues: FormValues }

const ConfigContext = createContext<Config | undefined>(undefined)

const ConfigProvider = ({
  children,
  config,
}: {
  children: ReactNode
  config: Config
}) => {
  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  )
}

const useConfig = () => {
  return useContext(ConfigContext)
}

const useDefaultVales = () => {
  const config = useConfig()
  return config?.defaultValues
}

export { ConfigProvider, useConfig, useDefaultVales }

import { useState } from 'react'
import { Select } from '@instructure/ui'

import { useController } from 'react-hook-form'
import { selectorAttrs } from '../../../mc-ui/utils/selectorAttrs'
import { District } from '../../../mc-resources/types/district'

type DistrictSelectorProps = {
  disabled?: boolean
  districts: Array<District>
  defaultDistrictId?: string
  onChange?
  emptyOptionId?: string
}

const DistrictSelector = ({
  disabled = false,
  districts = [],
  defaultDistrictId = '',
  onChange,
  emptyOptionId = null,
}: DistrictSelectorProps) => {
  const { field } = useController({ name: 'destinationDistrict.id' })

  const [inputValue, setInputValue] = useState(defaultDistrictId)
  const [isShowingOptions, setIsShowingOptions] = useState(false)
  const [highlightedOptionId, setHighlightedOptionId] = useState(null)
  const [filteredOptions, setFilteredOptions] = useState(districts)
  const [isInputFocused, setIsInputFocused] = useState(false)

  const getOptionById = (queryId) => {
    if (queryId === 'empty' || queryId === emptyOptionId)
      return { id: 'empty', name: '' }
    return districts.find(({ id }) => id.toString() === queryId)
  }

  const filterOptions = (value) => {
    return districts.filter((option) =>
      option.name.toLowerCase().startsWith(value.toLowerCase())
    )
  }

  const selectDistrict = (id) => {
    if (field.value == id) return

    const value =
      (id === 'empty' || id === null) && emptyOptionId
        ? emptyOptionId
        : id === 'empty'
        ? null
        : id
    field.onChange(value)
    onChange && onChange(value)
  }

  const matchValue = () => {
    if (filteredOptions.length === 1) {
      const onlyOption = filteredOptions[0]
      if (onlyOption.name.toLowerCase() === inputValue.toLowerCase()) {
        setInputValue(onlyOption.name)
        selectDistrict(onlyOption.id)
        setFilteredOptions(filterOptions(''))
      }
    }

    if (inputValue.length === 0) {
      selectDistrict(null)
    }

    if (field.value || filteredOptions.length === 0) {
      const selectedOption = getOptionById(field.value)
      if (selectedOption) setInputValue(selectedOption.name)
    }

    if (highlightedOptionId) {
      const highlightedOption = getOptionById(highlightedOptionId)
      const selectedOption = getOptionById(field.value)

      if (!selectedOption && inputValue !== highlightedOption?.name) {
        setInputValue('')
        setFilteredOptions(districts)
        selectDistrict(null)
      }
    }
  }

  const handleShowOptions = () => {
    setIsShowingOptions(true)
  }

  const handleHideOptions = () => {
    matchValue()
    setIsShowingOptions(false)
    setHighlightedOptionId(null)
  }

  const handleBlur = () => {
    setIsInputFocused(false)
    field.onBlur()
  }

  const handleFocus = () => {
    setIsInputFocused(true)
  }

  const handleHighlightOption = (event, { id }) => {
    event.persist()

    const option = getOptionById(id)
    if (!option) return

    setHighlightedOptionId(id)
    setInputValue(event.type === 'keydown' ? option.name : inputValue)
  }

  const handleSelectOption = (_, { id }) => {
    const option = getOptionById(id)
    if (!option) return

    selectDistrict(id)
    setInputValue(option.name)
    setIsShowingOptions(false)
    setFilteredOptions(districts)
  }

  const handleInputChange = (event) => {
    const value = event.target.value
    const newOptions = filterOptions(value)

    setInputValue(value)
    setFilteredOptions(newOptions)
    setHighlightedOptionId(newOptions.length > 0 ? newOptions[0].id : null)
    setIsShowingOptions(true)
    if (value === '') selectDistrict(null)
  }

  const syncFieldValueWithInput = () => {
    // return if still loading or user is searching something
    if (isInputFocused) {
      return
    }

    // if District is not set in field, but input is not empty, clear input
    if (!field.value && inputValue.length > 0) {
      setInputValue('')
      return
    }

    // if field is not empty, make sure to sync it with input
    if (field.value) {
      const selectedOption = getOptionById(field.value)
      if (selectedOption && inputValue !== selectedOption.name) {
        setInputValue(selectedOption.name)
      }
    }
  }

  syncFieldValueWithInput()

  return (
    <Select
      disabled={disabled}
      inputRef={field.ref}
      onBlur={handleBlur}
      inputValue={inputValue}
      renderLabel={'District'}
      onFocus={handleFocus}
      onInputChange={handleInputChange}
      isShowingOptions={isShowingOptions}
      onRequestShowOptions={handleShowOptions}
      onRequestHideOptions={handleHideOptions}
      onRequestSelectOption={handleSelectOption}
      onRequestHighlightOption={handleHighlightOption}
      assistiveText="Type or use arrow keys to navigate options."
      {...selectorAttrs('add-assessment-modal-district-dropdown')}
    >
      {emptyOptionId !== null && (
        <Select.Option
          id={'empty'}
          key={'empty'}
          isHighlighted={'empty' === highlightedOptionId}
          isSelected={'empty' === field.value}
          {...selectorAttrs(`add-assessment-modal-district-dropdown--empty`)}
        >
          &nbsp;
        </Select.Option>
      )}
      {filteredOptions.length > 0 &&
        filteredOptions.map((option) => {
          const { id, name, state } = option
          if (emptyOptionId === id) return
          return (
            <Select.Option
              id={id}
              key={id}
              isHighlighted={id === highlightedOptionId}
              isSelected={id === field.value}
              {...selectorAttrs(
                `add-assessment-modal-district-dropdown--${id}`
              )}
            >
              {`${name}, ${state.code}`}
            </Select.Option>
          )
        })}
    </Select>
  )
}

export { DistrictSelector }

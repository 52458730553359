/**
 * Provides a consistent way to add data-qa and data-impact-id attributes to a component
 * Usage example:
 *
 * <div {...selectorAttrs('my-component')} />
 *
 * @param selector
 */
export const selectorAttrs = (selector: string): SelectorAttrs => {
  return {
    'data-qa': selector,
    'data-impact-id': selector,
  }
}

export type SelectorAttrs = {
  'data-qa': string
  'data-impact-id': string
}

import { AbstractResource } from '../AbstractResource'
import { District } from '../types'

class MaterialShellContentConsumersResource extends AbstractResource {
  _endpointUrl = '/api/internal/content_consumers'
  _camelizeKeys = true

  list: () => Promise<District[]>
}

export { MaterialShellContentConsumersResource }

import { createContext, ReactNode, useContext } from 'react'

type FeatureFlags = 'instuiNavigation' | 'useRefactoredLearnosityValidation'

export type FeatureFlagMap<T extends string = FeatureFlags> = {
  [K in T | string]?: boolean
}

interface FeatureFlagsProviderProps<T extends string = FeatureFlags> {
  children: ReactNode
  values: FeatureFlagMap<T>
}

const FeatureFlagsContext = createContext<FeatureFlagMap<string>>({})

export const FeatureFlagsProvider = ({
  children,
  values = {},
}: FeatureFlagsProviderProps) => (
  <FeatureFlagsContext.Provider value={values}>
    {children}
  </FeatureFlagsContext.Provider>
)

export const useFeatureFlags = <T extends string = FeatureFlags>() => {
  return useContext<FeatureFlagMap<T>>(FeatureFlagsContext)
}

import { useQuery } from '@tanstack/react-query'
import { MaterialShellInitConfigResource } from '../../../../mc-resources/materials/MaterialShellInitConfigResource'
import { MaterialShellContentConsumersResource } from '../../../../mc-resources/materials/MaterialShellContentConsumersResource'
import { useConfig } from '../contexts/ConfigContext'
import { Alignments } from '../../../../mc-resources/internal/Alignments'

export const useShellInitConfigQuery = () => {
  const { classroomId, mapId } = useConfig()

  const materialShellInitConfigResource =
    MaterialShellInitConfigResource.createInstance()

  const query = useQuery(
    ['getMaterialShellInitConfig', classroomId, mapId],
    () =>
      materialShellInitConfigResource.getConfig({
        classroomId: classroomId,
        curriculumMapId: mapId,
      }),
    {
      staleTime: Infinity,
    }
  )

  return { ...query }
}

export const useSubjectsQuery = (enabled: boolean) => {
  const alignmentsResource = Alignments.createInstance()

  const { data: subjects, isFetching: isSubjectsFetching } = useQuery(
    ['alignment_subjects'],
    () => alignmentsResource.subjects(),
    {
      staleTime: Infinity,
      enabled: enabled,
      select: (data) => data.subjects,
    }
  )

  return {
    subjects,
    isSubjectsFetching,
  }
}

export const usePathwaysQuery = (subjectID: number, enabled: boolean) => {
  const alignmentsResource = Alignments.createInstance({ subjectID })

  const { data: pathways, isFetching: isPathwaysFetching } = useQuery(
    ['alignment_pathways', subjectID],
    () => alignmentsResource.pathways(),
    {
      staleTime: Infinity,
      enabled: !!subjectID && enabled,
      select: (data) =>
        data.states.map((state) => {
          return {
            ...state,
            pathways: data.pathways.filter(
              (pathway) => pathway.state_id === state.id
            ),
          }
        }),
    }
  )

  return {
    pathways,
    isPathwaysFetching,
  }
}

export const useClassObjectivesQuery = (
  subjectID: number,
  pathwayID: number,
  enabled: boolean
) => {
  const alignmentsResource = Alignments.createInstance({ subjectID, pathwayID })

  const { data: classObjectives, isFetching: isClassObjectivesFetching } =
    useQuery(
      ['alignment_class_objectives', subjectID, pathwayID],
      () => alignmentsResource.classObjectives(),
      {
        staleTime: Infinity,
        enabled: !!pathwayID && enabled,
        select: (data) => data.class_objectives,
      }
    )

  return {
    classObjectives,
    isClassObjectivesFetching,
  }
}

export const useShellContentConsumersResource = () => {
  const materialShellContentConsumersResource =
    MaterialShellContentConsumersResource.createInstance()

  const query = useQuery(
    ['getMaterialShellContentConsumers'],
    () => materialShellContentConsumersResource.list(),
    {
      staleTime: 30 * (60 * 1000), // 30 mins
    }
  )

  return { ...query }
}

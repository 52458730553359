import { RadioInput, RadioInputGroup } from '@instructure/ui-radio-input'
import { useRadioGroup } from '../../../../mc-ui/utils/hooks/forms/useRadioGroupWithReactHookForm'
import { FormValues } from '../types'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'

const AssessmentType = ({ defaultValue, onChange, disabled = false }) => {
  return (
    <RadioInputGroup
      disabled={disabled}
      description={'Assessment Type'}
      {...useRadioGroup<FormValues, 'assessmentType'>({
        name: 'assessmentType',
        defaultValue: defaultValue,
        onChange,
      })}
    >
      <RadioInput
        key={'formative'}
        value={'formative'}
        label={'Formative'}
        {...selectorAttrs('add-assessment-modal-assessment-type--formative')}
      />

      <RadioInput
        key={'benchmark'}
        value={'benchmark'}
        label={'Benchmark'}
        {...selectorAttrs('add-assessment-modal-assessment-type--benchmark')}
      />
    </RadioInputGroup>
  )
}
export { AssessmentType }

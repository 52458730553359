import type { FilterOptionOption, GroupBase, Options } from '../types'
import type { Props } from '../index'

type OptionUtilsContext<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> = {
  isOptionDisabled: Props<Option, IsMulti, Group>['isOptionDisabled']
  isOptionSelected: Props<Option, IsMulti, Group>['isOptionSelected']
  getOptionValue: Props<Option, IsMulti, Group>['getOptionValue']
  getOptionLabel: Props<Option, IsMulti, Group>['getOptionLabel']
  filterOption: Props<Option, IsMulti, Group>['filterOption']
}

const useOptionUtils = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  context: OptionUtilsContext<Option, IsMulti, Group>
) => {
  const getOptionValue = (data: Option): string => {
    return context.getOptionValue(data)
  }

  const getOptionLabel = (data: Option): string => {
    return context.getOptionLabel(data)
  }

  function isOptionSelected(option: Option, selectValue: Options<Option>) {
    if (selectValue.indexOf(option) > -1) return true
    if (typeof context.isOptionSelected === 'function') {
      return context.isOptionSelected(option, selectValue)
    }
    const candidate = context.getOptionValue(option)
    return selectValue.some((i) => getOptionValue(i) === candidate)
  }

  function isOptionDisabled(option: Option, selectValue: Options<Option>) {
    return typeof context.isOptionDisabled === 'function'
      ? context.isOptionDisabled(option, selectValue)
      : false
  }

  const filterOption = (
    option: FilterOptionOption<Option>,
    inputValue: string
  ) => {
    return context.filterOption
      ? context.filterOption(option, inputValue)
      : true
  }

  return {
    getOptionValue,
    getOptionLabel,
    isOptionSelected,
    isOptionDisabled,
    filterOption,
  }
}

export { useOptionUtils }

import { useQuery } from '@tanstack/react-query'
import { CurrentTeacherResource } from 'mc-resources/current-teacher'

/**
 * Returns a query to get the Current Teacher JSON data
 */
export const useCurrentTeacher = () => {
  const currentTeacherResource = new CurrentTeacherResource()
  const query = useQuery({
    queryKey: ['currentTeacher'],
    queryFn: () => currentTeacherResource.read(),
    staleTime: 1000 * 60 * 60,
  })

  return {
    ...query,
  }
}

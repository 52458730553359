import { Button } from '@instructure/ui-buttons'
import { selectorAttrs } from '../../../mc-ui/utils/selectorAttrs'
import type { ButtonProps } from '@instructure/ui-buttons'
import { FormValues } from './types'
import { useAddFormContext } from './hooks/useAddForm'
import { useCreate } from './hooks/mutations'
import { CreateMaterialResponseDTO } from '../../../mc-resources/materials'
import { useUploadFile } from './hooks/useUploadFile'
import { useState } from 'react'
import { useAlertsContext } from '../../../mc-ui/context/AlertsContext'
import { useConfig } from './contexts/ConfigContext'

const Footer = ({ onCancel }: { onCancel: ButtonProps['onClick'] }) => {
  const { handleSubmit, formState } = useAddFormContext()
  const { isValid } = formState
  const createMaterial = useCreate()
  const [isUploadingCsv, setIsUploadingCsv] = useState(false)
  const disabled =
    !isValid ||
    createMaterial.isLoading ||
    createMaterial.isSuccess ||
    isUploadingCsv

  const { uploadFile } = useUploadFile()
  const { addAlert } = useAlertsContext()
  const { classroomId, mapId } = useConfig()

  const handleCsvUpload = async (file: File) => {
    setIsUploadingCsv(true)
    await uploadFile(
      { file: file, validateOnly: false },
      {
        onSuccess: (result) => {
          if (result.materialId) {
            window.location.href = '/materials/' + result.materialId + '/edit'
          }
        },
        onError: () => {
          addAlert({
            variant: 'error',
            message: `An error has occurred while uploading the file. Please try again later.`,
          })
        },
      }
    )
    setIsUploadingCsv(false)
  }

  const handleFormSubmit = async (data: FormValues) => {
    if (data.materialCsv) {
      await handleCsvUpload(data.materialCsv)
      return
    }

    const createParams = {
      ...data,
      assessmentType: undefined,
      distbenchmark: data.assessmentType === 'benchmark',
      sourceClassroom: { id: classroomId ? +classroomId : null },
      sourceCurriculumMap: { id: mapId ? +mapId : null },
    }

    createMaterial.mutate(createParams, {
      onSuccess: (material: CreateMaterialResponseDTO) => {
        if (material.id) {
          window.location.href = '/materials/' + material.id + '/edit'
        }
      },
    })
  }

  return (
    <>
      <Button
        margin="0 x-small 0 0"
        onClick={onCancel}
        {...selectorAttrs('add-assessment-modal-cancel')}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        onClick={handleSubmit(handleFormSubmit)}
        disabled={disabled}
        type="button"
        {...selectorAttrs('add-assessment-modal-submit')}
      >
        Next
      </Button>
    </>
  )
}

export { Footer }

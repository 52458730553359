import { AbstractResource } from '../AbstractResource'
import { MaterialCsvUploadResultDTO } from './types'
import { camelizeKeys } from '../../mc-sdk/case-utils'

class MaterialCsv extends AbstractResource {
  _endpointUrl = '/materials/upload_csv'
  materialShowPath = (materialId) => `/materials/${materialId}`

  uploadCsv(
    file: File,
    validateOnly: boolean
  ): Promise<MaterialCsvUploadResultDTO> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('name', file.name)

    const resourceId = validateOnly ? 'validate' : ''
    return super.upload(formData, resourceId).then((data) => camelizeKeys(data))
  }
}

export { MaterialCsv }

import { AbstractResource } from '../AbstractResource'
import { camelizeKeys, snakeCaseKeys } from "../../mc-sdk/case-utils"

class MaterialReportFilterClassObjectivesQueryResource extends AbstractResource {
  constructor() {
    super()
    this._endpointUrl = '/api/internal/materials/reports/filters/class_objectives'
  }

  list(query) {
    return super.list(snakeCaseKeys(query)).then(response => camelizeKeys(response))
  }
}

export { MaterialReportFilterClassObjectivesQueryResource }

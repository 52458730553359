import { AbstractResource } from 'mc-resources/AbstractResource'
import { Scale } from '../types'

export interface ScoringTrayConfigDTO {
  scales: Array<Scale>
  defaultScaleId: string
}

class MaterialScoringTrayConfigResource extends AbstractResource {
  _endpointUrl = '/api/internal/materials/new'
  _camelizeKeys = true

  list: (query?) => Promise<ScoringTrayConfigDTO>
}

export { MaterialScoringTrayConfigResource }

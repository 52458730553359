import { MaterialCsv } from '../../../../mc-resources/internal/MaterialCsv'
import { useState } from 'react'
import type { FormMessage } from '@instructure/ui-form-field'
import { useMutation } from '@tanstack/react-query'
import { useAddFormContext } from './useAddForm'
import { FormValues } from '../types'
import type { UseFormSetValue } from 'react-hook-form'
import { SourceType } from 'mc-resources/materials/MaterialResource'
import { MaterialCsvUploadResultDTO } from '../../../../mc-resources/internal/types'
import { AlignmentSourceDTO } from 'mc-resources/materials/MaterialShellInitConfigResource'

function fillInForm(
  data: MaterialCsvUploadResultDTO,
  setValue: UseFormSetValue<FormValues>
) {
  const { material } = data
  const {
    subject,
    pathway,
    classObjective,
    subjectId,
    pathwayId,
    classObjectiveId,
  } = material
  setValue('title', material.title, { shouldValidate: true })
  setValue('scale.id', material.scaleId, { shouldValidate: true })
  setValue('destinationDistrict.id', material.targetDistrict, {
    shouldValidate: true,
  })
  setValue('sourceType', SourceType.ITEM_BANK, { shouldValidate: true }) // Material CSV always has source as Item
  setValue(
    'assessmentType',
    material.distbenchmark ? 'benchmark' : 'formative',
    {
      shouldValidate: true,
    }
  )
  // Fill in the alignment UI:
  const csvAlignmentSource: AlignmentSourceDTO = {
    source: 'csv',
    name: classObjective,
    id: 0,
    alignment: {
      subject: {
        id: parseInt(subjectId),
        name: subject,
      },
      pathway: {
        id: parseInt(pathwayId),
        name: pathway,
        stateId: null,
        subjectId: null,
        custom: null,
      },
      classObjective: {
        id: parseInt(classObjectiveId),
        name: classObjective,
        stateId: null,
        subjectId: null,
      },
    },
  }
  setValue('sourceCsvAlignment', csvAlignmentSource, { shouldValidate: true })
  // Set params to be passed on with the request:
  setValue('alignment.subject.id', parseInt(material.subjectId), {
    shouldValidate: true,
  })
  setValue('alignment.classObjective.id', parseInt(material.classObjectiveId), {
    shouldValidate: true,
  })
  setValue('alignment.pathway.id', parseInt(material.pathwayId), {
    shouldValidate: true,
  })
  setValue('privacyLevel', material.privacy, { shouldValidate: true })
}

export const useUploadFile = () => {
  const uploadResource = MaterialCsv.createInstance()
  const [formMessages, setMessages] = useState<FormMessage[]>([])
  const { setValue } = useAddFormContext()
  const mutation = useMutation(
    ({ file, validateOnly }: { file: File; validateOnly: boolean }) => {
      setMessages([
        {
          text: 'Uploading...',
          type: 'hint',
        },
      ])
      return uploadResource.uploadCsv(file, validateOnly)
    },
    {
      onError: (error, context) => {
        setMessages([
          {
            text: context.file.name,
            type: 'error',
          },
          {
            text: 'An error has occurred while uploading the file. Please try again later.',
            type: 'error',
          },
        ])
      },
      onSuccess: (data, context) => {
        if (!data.success) {
          const formMessages: FormMessage[] = [
            {
              text: context.file.name,
              type: 'error',
            },
          ]
          data.errors.map((error) => {
            formMessages.push({ text: error, type: 'error' })
          })

          setMessages(formMessages)
          return
        }

        if (context.validateOnly) {
          fillInForm(data, setValue)
        }

        setMessages([])
      },
    }
  )
  return {
    uploadFile: mutation.mutate,
    isLoading: mutation.isLoading,
    errors: formMessages,
  }
}

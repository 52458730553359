import { useCallback } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { SourceType } from '../../../mc-resources/materials'
import { PrivacyLevel } from '../../../mc-resources/internal/types'
import { usePrivacyOptions } from './queries'
import type { MaterialPrivacyConfig } from '../PrivacySelector/types'
import { CurrentTeacherDTO } from '../../../mc-resources/current-teacher'

export const useMaterialPrivacy = ({
  config,
  currentTeacher,
  fileIsAttached = false,
  enabled = true,
}: {
  config?: MaterialPrivacyConfig
  currentTeacher?: Pick<CurrentTeacherDTO, 'isDistadmin'>
  fileIsAttached?: boolean
  enabled?: boolean
}) => {
  const {
    districtIsNetwork,
    defaultMaterialPrivacy,
    defaultPrivacyOptions,
    defaultPrivacyOptionsTimestamp,
  } = config || {}
  const { isDistadmin } = currentTeacher || {}
  const { getValues, resetField } = useFormContext()

  const sourceType = useWatch({ name: 'sourceType' })
  const assessmentType = useWatch({ name: 'assessmentType' })

  const {
    privacyOptions: options,
    isPrivacyOptionsFetching,
    privacyOptionsUpdatedAt,
  } = usePrivacyOptions(
    assessmentType,
    sourceType,
    fileIsAttached,
    defaultPrivacyOptions,
    defaultPrivacyOptionsTimestamp,
    enabled
  )

  const getDefault = useCallback(() => {
    const [sourceType, assessmentType] = getValues([
      'sourceType',
      'assessmentType',
    ])

    let defaultOption

    if (assessmentType === 'benchmark') {
      defaultOption = PrivacyLevel.DISTRICT
    } else if (
      defaultMaterialPrivacy &&
      options?.length &&
      options?.map((o) => o.value).includes(defaultMaterialPrivacy)
    ) {
      defaultOption = defaultMaterialPrivacy
    } else if (sourceType === SourceType.ITEM_BANK) {
      if (districtIsNetwork) {
        defaultOption = isDistadmin
          ? PrivacyLevel.NETWORK
          : PrivacyLevel.DISTRICT
      } else {
        defaultOption = PrivacyLevel.DISTRICT
      }
    } else {
      defaultOption = fileIsAttached ? PrivacyLevel.PUBLIC : PrivacyLevel.ME
    }

    if (!options?.map((o) => o.value).includes(defaultOption)) {
      defaultOption = PrivacyLevel.ME
    }

    return defaultOption
  }, [
    isDistadmin,
    defaultMaterialPrivacy,
    districtIsNetwork,
    fileIsAttached,
    getValues,
    options,
  ])

  const resetDefault = useCallback(() => {
    resetField('privacyLevel', { defaultValue: getDefault() })
  }, [getDefault, resetField])

  return {
    options,
    isFetchingOptions: isPrivacyOptionsFetching,
    optionsUpdatedAt: privacyOptionsUpdatedAt,
    getDefault,
    resetDefault,
  }
}

import { ReactNode, useState } from 'react'
import {
  IconWarningLine,
  Button,
  CloseButton,
  Flex,
  Heading,
  Modal,
  Spinner,
  Text,
  View,
} from '@instructure/ui'

export interface ConfirmModalProps {
  cancelButtonCaption?: string
  confirmButtonCaption?: string
  confirmButtonCaptionOnClick?: string
  confirmButtonColor?:
    | 'primary'
    | 'primary-inverse'
    | 'secondary'
    | 'success'
    | 'danger'
  confirmLoading?: boolean
  content?: ReactNode
  heading?: string
  icon?: ReactNode
  showIcon?: boolean
  bodyMinHeight?: string
  message: string | ReactNode
  onCancel: () => void
  onConfirm: () => void
  onExited?: () => void
  showConfirmButton?: boolean
  confirmButtonIcon?: ReactNode
  open: boolean
}

const ConfirmModal = ({
  cancelButtonCaption = 'Cancel',
  confirmButtonCaption = 'Ok',
  confirmButtonCaptionOnClick = null,
  confirmButtonColor = 'danger',
  confirmLoading,
  content = null,
  heading = 'Confirm your action',
  icon = null,
  showIcon = true,
  bodyMinHeight = '13rem',
  message,
  onCancel,
  onConfirm,
  onExited = () => null,
  showConfirmButton = true,
  open = false,
  confirmButtonIcon = null,
  ...rest
}: ConfirmModalProps) => {
  const [confirmClicked, setConfirmClicked] = useState(false)
  const captionOnClick = confirmButtonCaptionOnClick || confirmButtonCaption
  // Create some fallback behavior for components not using the `confirmLoading` prop
  const useFallbackConfirmLoading =
    confirmLoading === undefined || confirmLoading === null
  const showLoadingSpinner =
    confirmLoading || (useFallbackConfirmLoading && confirmClicked)

  const handleOnConfirm = () => {
    onConfirm()
    setConfirmClicked(true)
  }

  const handleOnExited = () => {
    onExited()
    setConfirmClicked(false)
  }

  const spinner = (
    <Spinner
      renderTitle={captionOnClick}
      size="x-small"
      variant="inverse"
      themeOverride={(_, { colors }) => ({
        inverseColor: colors.white,
      })}
    />
  )

  return (
    <Modal
      open={open}
      label={heading}
      onExited={handleOnExited}
      data-qa={rest['data-qa']}
      data-impact-id={rest['data-impact-id']}
    >
      <Modal.Header>
        <Flex alignItems={'center'}>
          {showIcon && (
            <View margin={'0 small 0 0'}>
              <Text
                size={'large'}
                lineHeight="fit"
                themeOverride={{ lineHeightFit: 1 }}
              >
                {icon || <IconWarningLine color={'warning'} />}
              </Text>
            </View>
          )}
          <Heading>{heading}</Heading>
          <View margin={'0 0 0 auto'}>
            <CloseButton
              onClick={onCancel}
              screenReaderLabel="Close"
              data-qa="confirm_modal_close_btn"
            />
          </View>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <View as={'div'} minHeight={bodyMinHeight} width={'24.5rem'}>
          <Text lineHeight={'default'}>{message}</Text>
          {content}
        </View>
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="secondary"
          margin="0 x-small"
          onClick={onCancel}
          data-qa="confirm_modal_cancel_btn"
        >
          {cancelButtonCaption}
        </Button>
        {showConfirmButton && (
          <Button
            color={confirmButtonColor}
            onClick={handleOnConfirm}
            renderIcon={showLoadingSpinner ? spinner : confirmButtonIcon}
            data-qa="confirm_modal_confirm_btn"
            disabled={showLoadingSpinner}
          >
            {showLoadingSpinner ? captionOnClick : confirmButtonCaption}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export { ConfirmModal }

import { RadioInput, RadioInputGroup } from '@instructure/ui-radio-input'
import { useRadioGroup } from '../../../../mc-ui/utils/hooks/forms/useRadioGroupWithReactHookForm'
import { FormValues } from '../types'
import { SourceType } from '../../../../mc-resources/materials'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'

const Source = ({
  defaultValue = SourceType.ITEM_BANK,
  onChange = null,
  disabled = false,
  assessmentType = null,
}) => {
  return (
    <RadioInputGroup
      disabled={disabled}
      description={'Source'}
      {...useRadioGroup<FormValues, 'sourceType'>({
        name: 'sourceType',
        defaultValue: defaultValue,
        onChange,
      })}
    >
      <RadioInput
        key={SourceType.ITEM_BANK}
        value={SourceType.ITEM_BANK}
        label={'Item'}
        {...selectorAttrs('add-assessment-modal-source-type--item')}
      />

      <RadioInput
        key={SourceType.DOCUMENT}
        value={SourceType.DOCUMENT}
        label={'Document Based'}
        {...selectorAttrs('add-assessment-modal-source-type--document-based')}
      />

      {assessmentType === 'formative' && (
        <RadioInput
          key={SourceType.RAW}
          value={SourceType.RAW}
          label={'Raw Score'}
          {...selectorAttrs('add-assessment-modal-source-type--raw-score')}
        />
      )}
    </RadioInputGroup>
  )
}
export { Source }

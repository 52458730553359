import { useOptionUtils } from './useOptionUtils'
import {
  GroupBase,
  NormalizedGroupOrOption,
  NormalizedOption,
  Options,
} from '../types'
import { OptionsOrGroups } from '../index'

export function isGroupGuard<Option, Group extends GroupBase<Option>>(
  obj: Group | Option
): obj is Group {
  return typeof obj === 'object' && obj !== null && 'options' in obj
}

const useNormalizedOptions = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  optionUtils: ReturnType<typeof useOptionUtils<Option, IsMulti, Group>>
) => {
  let firstSelectedOptionIndex = -1

  function toNormalizedOption(
    option: Option,
    selectValue: Options<Option>,
    index: number
  ): NormalizedOption<Option> {
    const isDisabled = optionUtils.isOptionDisabled(option, selectValue)
    const isSelected = optionUtils.isOptionSelected(option, selectValue)
    const label = optionUtils.getOptionLabel(option)
    const value = optionUtils.getOptionValue(option)

    if (isSelected && firstSelectedOptionIndex === -1) {
      firstSelectedOptionIndex = index
    }

    return {
      type: 'option',
      data: option,
      isDisabled,
      isSelected,
      label,
      value,
      index,
    }
  }

  function normalizeGroup(
    group: Group,
    selectValue: Options<Option>,
    inputValue: string,
    index: number
  ): {
    group: NormalizedGroupOrOption<Option, Group>
    options: NormalizedOption<Option>[]
  } {
    let offset = 0
    const normalizedOptions = group.options
      .map((option) => {
        const normalizedOption = toNormalizedOption(
          option,
          selectValue,
          index + offset + 1
        )
        if (optionUtils.filterOption(normalizedOption, inputValue)) {
          offset++
          return normalizedOption
        }
        return null
      })
      .filter(Boolean)

    const groupWithNormalizedOptions: NormalizedGroupOrOption<Option, Group> = {
      type: 'group',
      data: group,
      options: normalizedOptions,
      index,
    }

    return { group: groupWithNormalizedOptions, options: normalizedOptions }
  }

  function buildNormalizedOptions(
    options: OptionsOrGroups<Option, Group>,
    selectValue: Options<Option>,
    inputValue: string
  ): NormalizedGroupOrOption<Option, Group>[] {
    let normalizedOptionIndex = 0

    return options.reduce<NormalizedGroupOrOption<Option, Group>[]>(
      (acc, groupOrOption) => {
        if (isGroupGuard(groupOrOption)) {
          const { group, options } = normalizeGroup(
            groupOrOption,
            selectValue,
            inputValue,
            normalizedOptionIndex
          )

          if (options.length > 0) {
            normalizedOptionIndex += options.length + 1
            acc.push(group, ...options)
          }
        } else {
          const normalizedOption = toNormalizedOption(
            groupOrOption,
            selectValue,
            normalizedOptionIndex
          )
          if (optionUtils.filterOption(normalizedOption, inputValue)) {
            normalizedOptionIndex++
            acc.push(normalizedOption)
          }
        }
        return acc
      },
      []
    )
  }

  return {
    buildNormalizedOptions,
    getFirstSelectedOptionIndex: () => firstSelectedOptionIndex,
    toNormalizedOption,
  }
}

export { useNormalizedOptions }

const generateStyle = (componentTheme, props, state) => {

  const {zIndex, spacingSmall, spacingLarge, largeMin} = componentTheme

  const center = {
    left: '50%',
    right: 'auto',
    transform: `translateX(-50%)`,
  }

  const right = {
    justifyContent: `flex-end`,
  }

  const left = {
    justifyContent: 'flex-start',
  }

  const rightLarge = {
    right: spacingLarge,
    left: 'auto',
  }

  const leftLarge = {
    left: spacingLarge,
    right: 'auto',
  }

  const top = {
    top: spacingSmall,
  }

  const bottom = {
    bottom: spacingSmall,
  }

  const topLarge = {
    top: spacingLarge,
  }

  const bottomLarge = {
    bottom: spacingLarge,
  }

  const verticalStyles = {
    top,
    bottom
  }

  const horizontalStyles = {
    right,
    left
  }

  const verticalStylesLarge = {
    top: topLarge,
    bottom: bottomLarge
  }
  const horizontalStylesLarge = {
    right: rightLarge,
    center: center,
    left: leftLarge
  }

  const {position: {vertical, horizontal}} = props

  return {
    root: {
      zIndex: zIndex,
      position: 'fixed',
      display: 'flex',
      boxSizing: 'border-box',
      left: spacingSmall,
      right: spacingSmall,
      justifyContent: 'center',
      alignItems: 'center',
      ...verticalStyles[vertical],
      ...horizontalStyles[horizontal],

      [`@media screen and (${largeMin})`]: {
        ...verticalStylesLarge[vertical],
        ...horizontalStylesLarge[horizontal],
      }
    }
  }
}

export default generateStyle
import { createContext, useContext } from 'react'
import { useAlerts } from '../utils/hooks/useAlerts'
import type { UseAlerts } from '../utils/hooks/useAlerts'

type ContextState = UseAlerts | Record<string, never>

const AlertsContext = createContext<ContextState>({})

const AlertsProvider = ({ children }) => {
  const state = useAlerts()
  return (
    <AlertsContext.Provider value={state}>{children}</AlertsContext.Provider>
  )
}

const useAlertsContext = (): ContextState => {
  return useContext(AlertsContext)
}

export { AlertsProvider, useAlertsContext }

import { useMutation } from '@tanstack/react-query'
import {
  MaterialResource,
  CreateMaterialDTO,
} from '../../../../mc-resources/materials'

export const useCreate = () => {
  return useMutation((data: CreateMaterialDTO) => {
    return MaterialResource.createInstance().create(data)
  })
}

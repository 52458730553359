import { QuestionListDTO } from '../questions'
import { AbstractResource } from '../AbstractResource'
import { snakeCaseKeys } from '../../mc-sdk/case-utils'

export type MaterialPassagesQueryDTO = QuestionListDTO

export type MaterialPassagesResponseDTO = {
  passages: MaterialPassageDTO[]
  total: number
}

export type MaterialPassageDTO = {
  id: number
  title: string
  itemCount: number
}

class MaterialPassagesResource extends AbstractResource {
  _endpointUrl = '/api/internal/questions/passages'
  _camelizeKeys = true
  _snakeCaseKeys = true

  list(query: MaterialPassagesQueryDTO): Promise<MaterialPassagesResponseDTO> {
    return this.fetchResource(this._endpointUrl, {
      method: 'POST',
      body: JSON.stringify(snakeCaseKeys(query)),
    }).then((data) => data)
  }
}

export { MaterialPassagesResource }

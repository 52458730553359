import { ScreenReaderContent, View } from '@instructure/ui'
import { Alignment } from './Alignment'
import { Source } from './Source'
import { AssessmentType } from './AssessmentType'
import { FormFieldGroup } from '@instructure/ui-form-field'
import { Title } from './Title'
import { MaterialCsvTemplate } from './MaterialCsvTemplate'
import { PrivacySelector } from '../../PrivacySelector'
import { Scale } from './Scale'
import {
  useShellContentConsumersResource,
  useShellInitConfigQuery,
} from '../hooks/queries'
import { useAddFormContext } from '../hooks/useAddForm'
import { useMaterialPrivacy } from '../../hooks/useMaterialPrivacy'
import { LoadingSpinner } from '../../../classroom/ClassroomTabs/components/LoadingSpinner'
import { DistrictSelector } from '../../DistrictSelector'
import { SourceType } from '../../../../mc-resources/materials'
import { MaterialPrivacyConfig } from '../../types'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'
import { useEffect } from 'react'
import { useDefaultVales } from '../contexts/ConfigContext'
import { useCurrentTeacher } from '../../../material/AuthorItem/hooks/useCurrentTeacher'

/**
 * MaterialShellModal AddForm component
 *
 * Contains basic form fields for adding a new assessment
 * Feel free to extend/change it according to your needs
 *
 */
const AddForm = () => {
  const { data: config, isFetching } = useShellInitConfigQuery()
  const { data: teacher } = useCurrentTeacher()

  const privacyConfig: MaterialPrivacyConfig = {
    ...config,
    defaultMaterialPrivacy: config?.defaultMaterialPrivacy,
  }
  const {
    options,
    isFetchingOptions,
    optionsUpdatedAt,
    getDefault,
    resetDefault: resetDefaultPrivacy,
  } = useMaterialPrivacy({
    config: privacyConfig,
    currentTeacher: teacher,
  })
  const { data: contentConsumers, isLoading: isLoadingContentConsumers } =
    useShellContentConsumersResource()
  const { watch, resetField, reset, getValues, setValue } = useAddFormContext()
  const defaultValues = useDefaultVales()

  useEffect(() => {
    if (config) {
      reset({
        ...defaultValues,
        sourceType: config.defaultSource,
      })
    }
  }, [config, defaultValues, reset])

  if (!config || isFetching || isLoadingContentConsumers || !options?.length)
    return (
      <View as="div">
        <LoadingSpinner></LoadingSpinner>
      </View>
    )

  const assessmentType = watch('assessmentType')
  const showDistrictSelector =
    config.canProvideContent && assessmentType === 'benchmark'

  const materialCsv = watch('materialCsv')
  const isMaterialCsvUploadMode = !!materialCsv

  const handleAssessmentTypeChange = (e, value) => {
    if (value === 'benchmark') {
      if (getValues('sourceType') === SourceType.RAW) {
        resetField('sourceType', { defaultValue: SourceType.ITEM_BANK })
      }
    } else if (value === 'formative') {
      setValue('destinationDistrict.id', null)
    }

    resetDefaultPrivacy()
  }

  return (
    <FormFieldGroup
      description={<ScreenReaderContent>Add assessment</ScreenReaderContent>}
    >
      {config.canUploadMaterialCsv && <MaterialCsvTemplate />}
      <Title disabled={isMaterialCsvUploadMode} />
      {config.canAuthorDistBenchmark && <Separator />}
      {config.canAuthorDistBenchmark && (
        <AssessmentType
          onChange={handleAssessmentTypeChange}
          disabled={isMaterialCsvUploadMode}
          defaultValue={config.defaultAssessmentType}
        />
      )}
      {showDistrictSelector && (
        <DistrictSelector
          disabled={isMaterialCsvUploadMode}
          districts={contentConsumers}
          emptyOptionId={config.defaultContentConsumerId}
        />
      )}
      <Separator />
      <Alignment
        disabled={isMaterialCsvUploadMode}
        alignmentSources={config.alignmentSources}
        defaultAlignmentSource={config.defaultAlignmentSource}
        defaultPathways={config.defaultPathways}
      />
      <Separator />
      <Source
        onChange={resetDefaultPrivacy}
        disabled={isMaterialCsvUploadMode}
        assessmentType={assessmentType}
        defaultValue={config.defaultSource}
      />
      <PrivacySelector
        key={optionsUpdatedAt}
        config={privacyConfig}
        disabled={isMaterialCsvUploadMode}
        options={options}
        isFetchingOptions={isFetchingOptions}
        defaultOption={getDefault()}
        {...selectorAttrs('add-assessment-modal-privacy-level')}
      />
      {config.scales.length > 1 && (
        <Scale
          scales={config.scales}
          defaultScaleId={config.defaultScaleId}
          disabled={isMaterialCsvUploadMode}
        />
      )}
    </FormFieldGroup>
  )
}

const Separator = () => {
  return (
    <View
      as={'div'}
      borderWidth={'small 0 0 0'}
      width={'100%'}
      margin={'0 0 medium 0'}
    />
  )
}
export { AddForm }
